import React, {useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {Button} from '../../components/Button/Button';
import APIKit from '../../utils/Apikit/apikit';

import './Checkout.css';
import {AddCard} from './components/AddCard/AddCard';
import { useNavigate } from 'react-router-dom';

export const Checkout = ({cartId, recipient, paymentCompleted}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const [paymentMethods, setPaymentMehtods] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    getCards();
  }, []);

  const getCards = () => {
    const onSuccess = ({data}) => {
      setPaymentMehtods(data.cards);
    };

    const onFailure = error => {
      console.log(error.response);
    };
    APIKit.get(`/api/v1/users/list-cards/`).then(onSuccess).catch(onFailure);
  };

  const onPressConfirm = () => {
    const payload = {
      nonce: activeCard,
      cart_id: cartId,
      recipient_id: Number(recipient),
    };

    const onSuccess = ({data}) => {
      paymentCompleted();
      setActiveCard(null);
      console.log(data);
      toast.success('Order Placed. Thank you for shopping with us 👋');
      navigate('/orders')
    };

    const onFailure = error => {
      toast.error('Payment failed. Please try again.');
      console.log('sd', error.response.data);
      error = error && error.response.data;
      error = error[Object.keys(error)[0]];
      console.log(error);
    };

    APIKit.post(`/api/v1/shops/complete-payment/`, payload)
      .then(onSuccess)
      .catch(onFailure);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const addCard = nonce => {
    const onSuccess = res => {
      console.log(res);
      getCards();
      closeModal();
      toast.success('Card added successfully 👋');
    };

    const onFailure = error => {
      getCards();
      closeModal();
      toast.error('Something went wrong');
    };
    const data = {
      nonce: nonce,
    };
    APIKit.post(`/api/v1/users/add-card/`, data)
      .then(onSuccess)
      .catch(onFailure);
  };

  return (
    <div className="checkout">
      {paymentMethods?.map(paymentMethod => {
        return (
          <div
            className={`cardData ${
              activeCard === paymentMethod.id ? 'activeCard' : undefined
            }`}
            onClick={() =>
              setActiveCard(
                activeCard === paymentMethod.id ? null : paymentMethod.id,
              )
            }>
            <div
              style={
                activeCard === paymentMethod.id
                  ? {height: 20, width: 20, background: '#30b868'}
                  : {height: 20, width: 20, background: '#FFF'}
              }></div>
            <div
              style={{
                display: 'flex',
                flexGrow: 2,
                flexDirection: 'column',
                marginLeft: 10,
              }}>
              <div>{paymentMethod.card_brand}</div>
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <div>**** **** **** {paymentMethod.last_4}</div>
                <div>
                  {paymentMethod.exp_month} / {paymentMethod.exp_year}
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <span
        onClick={openModal}
        style={{
          color: '#30b868',
          width: '100%',
          textAlign: 'right',
          cursor: 'pointer',
          textDecoration: 'underline',
          display: 'block',
          fontSize: '12px',
        }}>
        Add new card
      </span>

      {activeCard && (
        <Button
          title="Pay now"
          onClick={() => onPressConfirm()}
          style={{width: '100%', marginTop: '20px'}}
        />
      )}

      <AddCard
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        addCard={addCard}
      />
    </div>
  );
};
