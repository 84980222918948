import React, {useState} from 'react';

import './Tabs.css';

const Tab = ({label, isActive, onClick}) => {
  return (
    <button className={`tab ${isActive ? 'active' : ''}`} onClick={onClick}>
      {label}
    </button>
  );
};

export const TabContent = ({content}) => {
  return <div className="tab-content">{content}</div>;
};

export const TabContainer = ({children}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabClick = index => {
    setActiveTabIndex(index);
  };

  return (
    <div className="tab-container">
      <div className="tab-header">
        {children.map((child, index) => (
          <Tab
            key={index}
            label={child.props.label}
            isActive={activeTabIndex === index}
            onClick={() => handleTabClick(index)}
          />
        ))}
      </div>
      <div className="tab-body">{children[activeTabIndex]}</div>
    </div>
  );
};
