import React, {useEffect, useState} from 'react';
import {Button} from '../../components/Button/Button';
import {toast} from 'react-toastify';
import {Spinner} from '../../components/Spinner/Spinner';
import APIKit from '../../utils/Apikit/apikit';
import {AddCard} from '../Checkout/components/AddCard/AddCard';

import './PaymentMethods.css';

export const PaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(null);

  useEffect(() => {
    setLoading(true);
    getCards();
  }, []);

  const getCards = () => {
    const onSuccess = ({data}) => {
      setLoading(false);
      setPaymentMethods(data.cards);
    };

    const onFailure = error => {
      setLoading(false);
      console.log(error.response);
    };

    APIKit.get(`/api/v1/users/list-cards/`).then(onSuccess).catch(onFailure);
  };

  const confirmDelete = cardId => {
    if (window.confirm('Press OK to confirm.') == true) {
      deleteCard(cardId);
    }
  };

  const deleteCard = cardId => {
    setLoadingDelete(cardId);
    const onSuccess = () => {
      setTimeout(() => {
        getCards();
        toast.success('Card was successfully removed 👋');
        setLoadingDelete(null);
      }, 10000);
    };

    const onFailure = error => {
      setLoadingDelete(null);
      toast.success('Something went wrong, please try again');
    };

    APIKit.post(`/api/v1/users/delete-card/`, {card_id: cardId})
      .then(onSuccess)
      .catch(onFailure);
  };

  const addCard = nonce => {
    const onSuccess = res => {
      setLoading(true);
      setTimeout(() => {
        getCards();
        closeModal();
        toast.success('Card added successfully 👋');
      }, 10000);
    };

    const onFailure = error => {
      getCards();
      closeModal();
      toast.error('Something went wrong');
    };
    const data = {
      nonce: nonce,
    };
    APIKit.post(`/api/v1/users/add-card/`, data)
      .then(onSuccess)
      .catch(onFailure);
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Button title="Add new card" onClick={() => openModal()} />
      <div className="payments">
        {paymentMethods?.map(paymentMethod => {
          return (
            <div className="payment-cards">
              <div>
                <img
                  src={require('../../assets/images/credit-card.png')}
                  alt="Crossrem"
                  style={{width: '50px'}}
                />
                <div className="card-brand">{paymentMethod.card_brand}</div>
              </div>
              <div className="card-right">
                <div style={{fontWeight: 'bold'}}>
                  **** **** **** {paymentMethod.last_4}
                </div>
                <div style={{fontWeight: 'bold', marginTop: '10px'}}>
                  {paymentMethod.exp_month} / {paymentMethod.exp_year}
                </div>
                {loadingDelete === paymentMethod.id ? (
                  <div style={{width: '50px'}}>
                    <Spinner />
                  </div>
                ) : (
                  <div
                    onClick={() => confirmDelete(paymentMethod.id)}
                    className="remove-card">
                    Remove card
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <AddCard
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        addCard={addCard}
      />
    </div>
  );
};
