import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {toast} from 'react-toastify';
import * as yup from 'yup';
import APIKit from '../../../utils/Apikit/apikit';
import {Button} from '../../../components/Button/Button';
import {TextInput} from '../../../components/Fields/TextInput/TextInput';
import {Modal} from '../../../components/Modal/Modal';

let signupSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone_number: yup.string().required(),
  birthday: yup.date().required(),
  email: yup.string(),
  state: yup.string(),
  bvn: yup.string(),
});

export const AddSubscription = ({
  modalIsOpen,
  closeModal,
  beneficiary,
  plans,
  cards,
}) => {
  const [activeCard, setActiveCard] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const onSuccess = ({data}) => {
    toast.success(data);
    toast.success('Subscription Added! 👋');
    closeModal();
  };

  const startSubscription = () => {
    const onSuccess = () => {
      toast.success('Subscription Added! 👋');
      closeModal();
    };

    const onFailure = error => {
      console.log(error.response);
    };

    APIKit.post(`/api/v1/subscriptions/`, {
      plan: parseInt(selectedPlan),
      beneficiary: beneficiary.id,
      payment_source: activeCard,
    })
      .then(onSuccess)
      .catch(onFailure);
  };

  return (
    <Modal isOpen={modalIsOpen} onClose={closeModal}>
      <>
        <div className="shopping-cart-header">
          <span>Subscribe to Plan</span>
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px 0',
            padding: '0 20px',
          }}
          className="shopping-cart-body">
          <Formik
            initialValues={{
              first_name:
                beneficiary && beneficiary.first_name
                  ? beneficiary.first_name
                  : '',
              last_name:
                beneficiary && beneficiary.last_name
                  ? beneficiary.last_name
                  : '',
              phone_number:
                beneficiary && beneficiary.phone_number
                  ? beneficiary.phone_number
                  : '',
              birthday:
                beneficiary && beneficiary.birthday ? beneficiary.birthday : '',
              email: beneficiary && beneficiary.email ? beneficiary.email : '',
              state: 'Nigeria',
              bvn: beneficiary && beneficiary.bvn ? beneficiary.bvn : '',
            }}
            validationSchema={signupSchema}
            onSubmit={values => {
              APIKit.post(`/api/v1/subscriptions/beneficiaries/`, values)
                .then(onSuccess)
                .catch(error => {
                  if (error.response) {
                    console.log('E1', error.response.data);
                    console.log('E2', error.response.status);
                    console.log('E3', error.response.headers);
                  } else if (error.request) {
                    console.log('RQ', error.request);
                  } else {
                    console.log('Error', error.message);
                  }
                  console.log('CF', error.config);
                });
            }}>
            {() => (
              <Form>
                <Field
                  name="first_name"
                  placeholder="Name"
                  title="Name*"
                  disabled={true}
                  value={
                    beneficiary && beneficiary.first_name
                      ? beneficiary.first_name
                      : ''
                  }
                  component={TextInput}
                />
                <Field
                  name="last_name"
                  disabled={true}
                  value={
                    beneficiary && beneficiary.last_name
                      ? beneficiary.last_name
                      : ''
                  }
                  placeholder="Surname"
                  title="Surname*"
                  component={TextInput}
                />
                <Field
                  name="phone_number"
                  value={
                    beneficiary && beneficiary.phone_number
                      ? beneficiary.phone_number
                      : ''
                  }
                  placeholder="+234 ..."
                  disabled={true}
                  title="Phone Number*"
                  component={TextInput}
                />
                <Field
                  name="email"
                  disabled={true}
                  value={
                    beneficiary && beneficiary.email ? beneficiary.email : ''
                  }
                  placeholder="Email"
                  title="Email*"
                  component={TextInput}
                />
                <Field
                  name="birthday"
                  disabled={true}
                  value={
                    beneficiary && beneficiary.birthday
                      ? beneficiary.birthday
                      : ''
                  }
                  placeholder="Birthday"
                  title="Birthday*"
                  component={TextInput}
                />
                <Field
                  name="bvn"
                  disabled={true}
                  value={beneficiary && beneficiary.bvn ? beneficiary.bvn : ''}
                  placeholder="BVN"
                  title="BVN*"
                  component={TextInput}
                />
                {/* Add a dropdown select for plans */}

                <h4>Select Plan</h4>
                {plans?.length ? (
                  <>
                    <select
                      id="plan"
                      style={{
                        width: '100%',
                        height: '40px',
                        padding: '5px',
                        width: '100%',
                      }}
                      defaultValue={'0'}
                      //defaultChecked={recipient}
                      onChange={e => setSelectedPlan(e.target.value)}>
                      <option value="0">Select Plan</option>
                      {plans.map(plan => {
                        return (
                          <option value={plan.id}>
                            {plan.title} - ${plan.price}
                          </option>
                        );
                      })}
                    </select>
                  </>
                ) : null}
                <h4>Card on File</h4>
                {cards?.map(paymentMethod => {
                  return (
                    <div
                      className={`cardData ${
                        activeCard === paymentMethod.id
                          ? 'activeCard'
                          : undefined
                      }`}
                      onClick={() =>
                        setActiveCard(
                          activeCard === paymentMethod.id
                            ? null
                            : paymentMethod.id,
                        )
                      }>
                      <div
                        style={
                          activeCard === paymentMethod.id
                            ? {height: 20, width: 20, background: '#30b868'}
                            : {height: 20, width: 20, background: '#FFF'}
                        }></div>
                      <div
                        style={{
                          display: 'flex',
                          flexGrow: 2,
                          flexDirection: 'column',
                          marginLeft: 10,
                        }}>
                        <div>{paymentMethod.card_brand}</div>
                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                          }}>
                          <div>**** **** **** {paymentMethod.last_4}</div>
                          <div>
                            {paymentMethod.exp_month} / {paymentMethod.exp_year}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    alignItems: 'center',
                  }}>
                  <span className="disclaimer">
                    <h3>Disclaimer</h3>
                    <p>
                      Healthcare plan will start 30 days from the date of
                      purchase. All the healthcare plans are offered from Novo
                      HealtH Africa.
                    </p>
                  </span>
                </div>

                <Button
                  disabled={activeCard === null}
                  onClick={() => startSubscription()}
                  type="submit"
                  title="Start Subscription"
                />
              </Form>
            )}
          </Formik>
        </div>
      </>
    </Modal>
  );
};
