import React from 'react';

import logo from '../../assets/svg/logo.svg';
import logoMenu from '../../assets/svg/logo-menu.svg';
import {useNavigate} from 'react-router-dom';

export const Logo = ({size = 'auto', menu}) => {
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate('/explore')} className="logo">
      <img src={menu ? logoMenu : logo} style={{width: size}} alt="CrossRem" />
    </div>
  );
};
