import React from 'react';
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';

import {TextInput} from '../../../../../../components/Fields/TextInput/TextInput';
import {Button} from '../../../../../../components/Button/Button';
import {authEnum} from '../../Authentication';
import APIKit, {setClientToken} from '../../../../../../utils/Apikit/apikit';
import {useSetRecoilState} from 'recoil';
import {tokenState} from '../../../../../../store/token';

let signupSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const Login = ({goToRegister, goTo}) => {
  const setTokenState = useSetRecoilState(tokenState);
  const onSuccess = ({data}) => {
    // Set JSON Web Token on success
    setClientToken(data.key);
    setTokenState(data.key);
    goTo();
  };

  return (
    <div className="formik">
      <span>Log into your account</span>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={signupSchema}
        onSubmit={(values, actions) => {
          APIKit.post(`/dj-rest-auth/login/`, values)
            .then(onSuccess)
            .catch(error => {
              if (error.response) {
                toast.error('The email or password entered is incorrect.');
              }
            });
        }}>
        {() => (
          <Form>
            <Field
              name="email"
              placeholder="Email"
              title="Email*"
              component={TextInput}
            />
            <Field
              name="password"
              placeholder="Password"
              title="Password*"
              component={TextInput}
              type="password"
            />
            <Button type="submit" title="Login Now" style={{width: '100%'}} />

            <div className="user-exists">
              <span>Trouble trying to login? </span>
              <span onClick={() => goToRegister(authEnum.reset)}>
                Reset Password
              </span>
            </div>

            <div className="user-exists">
              <span>Don't have an account? </span>
              <span onClick={() => goToRegister(authEnum.signup)}>
                Register
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
