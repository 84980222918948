import React from 'react';
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';

import {TextInput} from '../../../../../../components/Fields/TextInput/TextInput';
import {Button} from '../../../../../../components/Button/Button';
import {authEnum} from '../../Authentication';
import APIKit, {setClientToken} from '../../../../../../utils/Apikit/apikit';
import {useSetRecoilState} from 'recoil';
import {tokenState} from '../../../../../../store/token';

let businessRegistrationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required('Password is a required field.'),
  password_confirmation: yup
    .string()
    .required('Repeat password a is required field.')
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
  name: yup.string(),
  shop_name: yup.string(),
  street_address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  phone_number: yup.string(),
});

export const BusinessRegistration = ({goToLogin, goTo}) => {
  const setTokenState = useSetRecoilState(tokenState);
  const header = {
    'content-type': 'application/json',
  };

  const onSuccess = ({data}) => {
    toast.success('Account Created! Welcome to CrossRem 👋');
    setClientToken(data.token);
    setTokenState(data.token);
    goTo();
  };

  return (
    <div
      className="formik"
      style={{
        padding: 20,
      }}>
      <span>
        Create a partner account. Start getting business from Diaspora!
      </span>
      <Formik
        initialValues={{
          email: '',
          password: '',
          password_confirmation: '',
          name: '',
          shop_name: '',
          street_address: '',
          city: '',
          state: '',
          country: '',
          phone_number: '',
        }}
        validationSchema={businessRegistrationSchema}
        onSubmit={(values, actions) => {
          APIKit.post(
            `/api/v1/partners/register/`,
            {...values},
            {
              headers: header,
            },
          )
            .then(onSuccess)
            .catch(error => {
              if (error.response) {
                toast.error(error.response.data.error);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js

                console.log('RQ', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log('CF', error.config);
            });
        }}>
        {() => (
          <Form>
            <Field
              name="email"
              placeholder="Email"
              title="Email*"
              component={TextInput}
            />
            <Field
              name="password"
              placeholder="Password"
              title="Password*"
              component={TextInput}
              type="password"
            />
            <Field
              name="password_confirmation"
              placeholder="Repeat Password"
              title="Repeat Password*"
              component={TextInput}
              type="password"
            />
            <Field
              name="name"
              placeholder="Name"
              title="Name"
              component={TextInput}
              type="input"
            />
            <Field
              name="shop_name"
              placeholder="Store Name"
              title="Store Name"
              component={TextInput}
              type="input"
            />
            <Field
              name="street_address"
              placeholder="Street Address"
              title="Street Address"
              component={TextInput}
              type="input"
            />
            <Field
              name="city"
              placeholder="City"
              title="City"
              component={TextInput}
              type="input"
            />
            <Field
              name="state"
              placeholder="State"
              title="State"
              component={TextInput}
              type="input"
            />
            <Field
              name="country"
              placeholder="Country"
              title="Country"
              component={TextInput}
              type="input"
            />
            <Field
              name="phone_number"
              placeholder="Phone Number"
              title="Phone Number"
              component={TextInput}
              type="input"
            />
            <Button
              type="submit"
              title="Register Now"
              style={{width: '100%'}}
            />

            <div className="user-exists">
              <span>I’m already member?</span>
              <span onClick={() => goToLogin(authEnum.login)}> Login</span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
