import React, {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {MapPin, Info} from 'feather-icons-react';
import {ShoppingCart} from '../../components/ShoppingCart/ShoppingCart';
import APIKit from '../../utils/Apikit/apikit';
import {
  shoppingCart,
  shoppingCartItems,
  shoppingList,
} from '../../store/shoppingCart';

import './Shop.css';
import {Button} from '../../components/Button/Button';
import {Spinner} from '../../components/Spinner/Spinner';
import {tokenState} from '../../store/token';
import {Product} from '../../components/Product/Product';

export const Shop = () => {
  const {shopid} = useParams();
  const [shop, setShop] = useState(null);
  const token = useRecoilValue(tokenState);
  const [cart, setCart] = useState(null);
  const shoppingCartState = useRecoilValue(shoppingCart);
  const shoppingListState = useSetRecoilState(shoppingList);
  const [loading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [moreUrl, setMoreUrl] = useState('');
  const [coverSrc, setCoverSrc] = useState(shop?.cover);
  const [productList, setProductList] = useState([]);
  const setshoppingCartItems = useSetRecoilState(shoppingCartItems);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    getShop();
  }, []);

  useEffect(() => {
    if (shop) {
      getProducts();
      getCartItems();
    }
  }, [shop]);

  const getShop = () => {
    APIKit.get(`/api/v1/shops/shops/${shopid}/`).then(res => {
      setShop(res.data);
    });
  };

  const getCartItems = () => {
    APIKit.get(`/api/v1/shops/carts/?is_purchased=false&shop=${shop.id}`).then(
      res => {
        setshoppingCartItems(res.data);

        setCart(res.data);
      },
    );
  };

  const getProducts = () => {
    setIsLoading(true);
    APIKit.get(`/api/v1/shops/products/?shop=${shop.id}`)
      .then(res => {
        setProductList(res.data.results);
        setIsLoading(false);
        if (res.data.next != null) {
          setHasMore(true);
          setMoreUrl(res.data.next);
        }
      })
      .catch(e => console.log(e));
  };

  const getMoreProducts = () => {
    setIsLoading(true);
    APIKit.get(moreUrl)
      .then(res => {
        setProductList([...productList, ...res.data.results]);
        setIsLoading(false);
        if (res.data.next != null) {
          setHasMore(true);
          setMoreUrl(res.data.next);
        } else {
          setHasMore(false);
        }
      })
      .catch(e => console.log(e));
  };

  const addProductToCart = product => {
    if (token) {
      const payload = {
        product: product.id,
        quantity: 1,
        status: 'string',
      };
      APIKit.post('/api/v1/shops/cart-items/', payload).then(async () => {
        getCartItems();
        setshoppingCartItems(cart);
      });
    } else {
      const shoppingList = localStorage.getItem('shoppingList');
      // TODO: Instead of id, put all product object so it can show in shopping cart when user loggout
      const list = JSON.parse(shoppingList) || [];

      let index = list.findIndex(obj => obj.product.id === product.id);

      // If the object exists, update it
      if (index !== -1) {
        list[index].quantity = list[index].quantity + 1;
      }
      // If the object does not exist, add it
      else {
        list.push({
          quantity: 1,
          status: 'string',
          product,
        });
      }
      shoppingListState(list);
      localStorage.setItem('shoppingList', JSON.stringify(list));
    }
  };

  const filterProducts = query => {
    const filtered = productList.filter(item =>
      item.name.toUpperCase().includes(query.toUpperCase()),
    );
    setFilteredProducts(filtered);
  };

  if (!shop) {
    return <Spinner />;
  }

  return (
    <div className="shop">
      <div className="shop-profile">
        <div className="cover">
          <img
            src={shop?.cover}
            alt="Crossrem"
            onError={() =>
              setCoverSrc(require('../../assets/images/landing.png'))
            }
          />
          <div>
            <span className="shop-name">{shop.name}</span>
            <p className="shop-description">{shop.description}</p>
          </div>
        </div>

        <div className="store-card-info-store">
          <div>
            <img src={shop.image} alt="Crossrem" />
          </div>
          <div className="store-card-info-store-more">
            <div>
              <Info size={20} color="#30B868" />
              <span>{shop.shop_category_info?.name_en}</span>
            </div>
            <div>
              <div style={{width: 20}}>
                <MapPin size={20} color="#30B868" />
              </div>
              <span>{`${shop.street_address ? shop.street_address + ',' : ''} ${
                shop.city ? shop.city + ',' : ''
              } ${shop.state ? shop.state + ',' : '.'} ${
                shop.country ? shop.country + '.' : '.'
              }`}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="shop-list">
          <div style={{padding: '20px'}}>
            <span className="shop-category">Products</span>
            <input
              className="shop-search"
              onChange={item =>
                item == ''
                  ? setFilteredProducts([])
                  : filterProducts(item.target.value)
              }
              type="search"
              placeholder="Search"
            />
          </div>
          <div className="shop-list-container">
            {filteredProducts && filteredProducts.length >= 1
              ? filteredProducts.map(product => (
                  <Product
                    product={product}
                    addProductToCart={addProductToCart}
                  />
                ))
              : productList.map(product => (
                  <Product
                    product={product}
                    addProductToCart={addProductToCart}
                  />
                ))}
            {loading && <Spinner />}
            {hasMore && (
              <div
                style={{
                  textAlign: 'center',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}>
                <Button onClick={() => getMoreProducts()} title="Load More" />
              </div>
            )}
          </div>
        </div>
      </div>
      {window.innerWidth <= 700 ? (
        shoppingCartState ? (
          <React.Suspense fallback={<Spinner />}>
            <ShoppingCart shop={shop} />
          </React.Suspense>
        ) : null
      ) : (
        <React.Suspense fallback={<Spinner />}>
          <ShoppingCart shop={shop} />
        </React.Suspense>
      )}
    </div>
  );
};
