import React, {useState} from 'react';
import {MapPin, Info} from 'feather-icons-react';
import {useNavigate} from 'react-router-dom';

import './StoreCard.css';
import {StoreImage} from './StoreImage';

const ComingSoonImg = require('../../assets/images/comingsoon.png');

export const StoreCard = ({shop}) => {
  const [coverSrc, setCoverSrc] = useState(shop.cover);
  const navigate = useNavigate();
  return (
    <div
      onClick={() =>
        shop.is_active && navigate(`/shop/${shop.id}`, {state: shop})
      }
      className="store-card"
      style={shop.is_active == false ? {opacity: 0.5} : {opacity: 1}}>
      <StoreImage src={shop.cover} />

      {shop.is_active == false ? (
        <div
          style={{
            background: '#0e76bc',
            flex: 1,
            position: 'relative',
            marginTop: -55,
            borderBottomLeftRadius: 10,
            borderBootoomRightRadius: 10,
            marginBottom: -15,
          }}>
          <p style={{color: '#FFF', textAlign: 'center', padding: 10}}>
            Coming Soon!
          </p>
        </div>
      ) : (
        <div className="store-card-logo-holder">
          <img className="store-card-logo" src={shop.image} alt="Crossrem" />
        </div>
      )}

      <div className="store-card-info">
        <div className="store-card-info-title">{shop.name}</div>
        <div className="store-card-info-description">{shop.description}</div>
        <div className="store-card-info-store">
          <div className="store-card-info-store-more">
            <div>
              <div style={{width: 20}}>
                <MapPin size={20} color="#30B868" />
              </div>
              <span className="store-card-info-address">{`${
                shop.street_address ? shop.street_address + ',' : ''
              } ${shop.city ? shop.city + ',' : ''} ${
                shop.state ? shop.state + ',' : '.'
              } ${shop.country ? shop.country + '.' : '.'}`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
