import React, {useEffect, useState} from 'react';
import {Button} from '../../components/Button/Button';
import {Spinner} from '../../components/Spinner/Spinner';
import APIKit from '../../utils/Apikit/apikit';
import {User} from 'feather-icons-react/build/IconComponents';
import './Recipients.css';
import { AddRecipient } from '../Checkout/components/AddRecipient/AddRecipient';

export const Recipients = () => {
  const [recipients, setRecipients] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRecipients();
  }, []);

  const getRecipients = () => {
    const onSuccess = (res) => {
      setLoading(false)
      setRecipients(res.data)
      console.log(res)
    }

    const onFailure = error => {
      setLoading(false);
      console.log(error.response);
    }

    APIKit.get(`/api/v1/deliveries/recipients/`).then(onSuccess).catch(onFailure);
  }

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div>
      <Button title="Add Recipient" onClick={() => openModal()} />
      <div className="payments">
        {recipients?.map(recipient => {
          return (
            <div className="payment-cards">
              <div>
                <User size={50} color="#000" />
                <div className="card-brand">{recipient.name}</div>
              </div>
              <div className="card-right">
                <div style={{fontWeight: 'bold'}}>
                {recipient.label} 
                </div>
                <div style={{fontWeight: 'bold', marginTop: '10px'}}>
                 {recipient?.email}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <AddRecipient
          getRecipients={getRecipients}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
      />
    </div>
  );
};
