import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import {RecoilRoot} from 'recoil';
import 'react-toastify/dist/ReactToastify.css';

import './index.css';
import reportWebVitals from './reportWebVitals';
import {AppRoutes} from './routes/routes';
import {setClientToken} from './utils/Apikit/apikit';

import {FluentProvider} from '@fluentui/react-components';
import {crossremLightTheme} from './utils/fluent-crossrem-light-theme';

const token = localStorage.getItem('token');

if (token) {
  setClientToken(token);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FluentProvider theme={crossremLightTheme} style={{height: '100%'}}>
      <RecoilRoot>
        <ToastContainer />
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </RecoilRoot>
    </FluentProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
