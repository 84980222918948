import React, {useEffect, useState, useRef, useCallback} from 'react';
import {NavLink} from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {Spinner} from '../../components/Spinner/Spinner';
import APIKit from '../../utils/Apikit/apikit';
import './Explore.css';
import {StoreCard} from '../../components/StoreCard/StoreCard';
import {useRecoilValue} from 'recoil';
import {masterSearch} from '../../store/systemStore';
import {ProductPreview} from '../../components/Product/ProductPreview';
import {Combobox, Option, Switch} from '@fluentui/react-components';

export const Explore = () => {
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState('Nigeria');
  const [categories, setCategories] = useState([]);
  const [shops, setShops] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [contriesComingSoon, setCountriesComingSoon] = useState([]);
  const [next, setNext] = useState();
  const [nextCategory, setNextCategory] = useState();
  const [viewByCategory, setViewByCategory] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const lastElementRef = useRef(null);
  const masterSearchInput = useRecoilValue(masterSearch);
  const [searchResults, setSearchResults] = useState([]);
  const [searchProducts, setSearchProducts] = useState([]);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');
  const responsive = {
    e3xtraLargeDesktop: {
      breakpoint: {max: 4000, min: 2000},
      items: 8,
    },
    e2xtraLargeDesktop: {
      breakpoint: {max: 2000, min: 1800},
      items: 7,
    },
    e1xtraLargeDesktop: {
      breakpoint: {max: 1800, min: 1500},
      items: 6,
    },
    extraLargeDesktop: {
      breakpoint: {max: 1500, min: 1350},
      items: 5,
    },
    superLargeDesktop: {
      breakpoint: {max: 1350, min: 1000},
      items: 4,
    },
    desktop: {
      breakpoint: {max: 1000, min: 768},
      items: 3,
    },
    tablet: {
      breakpoint: {max: 768, min: 464},
      items: 2,
    },
    mobile: {
      breakpoint: {max: 464, min: 0},
      items: 1,
    },
  };

  const search = useCallback(() => {
    APIKit.get('/api/v1/shops/search/?query=' + masterSearchInput)
      .then(res => {
        setSearchResults(res.data.shops);
        setSearchProducts(res.data.products);
      })
      .catch(e => console.log(e));
  }, [masterSearchInput]);

  useEffect(() => {
    if (masterSearchInput === '') {
      setSearchResults([]);
      return;
    }
    search(masterSearchInput, country);
  }, [masterSearchInput]);

  const getCountries = () => {
    APIKit.get('/api/v1/shops/shop-locations/')
      .then(res => {
        const availableCountries = res.data;
        setAvailableCountries(availableCountries);
        availableCountries.map(countries => {
          if (countries.country === country) {
            console.log(countries);

            setCities(countries.states);
          }
        });
      })
      .catch(e => console.log(e));
  };

  const getTopCategories = () => {
    APIKit.get(
      `/api/v1/shops/categories/top_shop_categories/?country=${country}&state=${city}`,
    )
      .then(res => {
        setCategories(res.data.results);
        setNextCategory(res.data.next);
        setDisabled(false);
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  const getTopCategoryPage = () => {
    if (nextCategory && !disabled) {
      setDisabled(true);

      APIKit.get(nextCategory)
        .then(res => {
          setCategories(prevCategories => [
            ...prevCategories,
            ...res.data.results,
          ]);
          setLoading(false);
          setNextCategory(res.data.next);
          setDisabled(false);
        })
        .catch(e => console.log(e));
    }
  };

  const getShops = () => {
    APIKit.get('/api/v1/shops/shops/?country__iexact=' + country)
      .then(res => {
        setShops(res.data.results);
        setNext(res.data.next);
        setLoading(false);
        setDisabled(false);
      })
      .catch(e => console.log(e));
  };

  const getShopsPage = () => {
    if (next && !disabled) {
      setDisabled(true);

      APIKit.get(next)
        .then(res => {
          setShops(prevShops => [...prevShops, ...res.data.results]);
          setNext(res.data.next);
          setDisabled(false);
        })
        .catch(e => console.log(e));
    }
  };

  useEffect(() => {
    getCountries();
    setLoading(true);

    if (viewByCategory) {
      getTopCategories();
    } else {
      getShops();
    }
  }, [viewByCategory, country, city]);

  useEffect(() => {
    let scrollTimeout;

    const handleScroll = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }

      scrollTimeout = setTimeout(() => {
        const scrollableDiv = document.documentElement || document.body;
        const scrollTop = window.pageYOffset || scrollableDiv.scrollTop || 0;
        const scrollHeight =
          scrollableDiv.scrollHeight || document.body.scrollHeight;
        const clientHeight =
          window.innerHeight || document.documentElement.clientHeight;
        const buffer = 200; // Adjust this value as needed

        // Use window.visualViewport for Safari on iOS
        const visualViewportHeight = window.visualViewport
          ? window.visualViewport.height
          : clientHeight;

        if (scrollHeight - scrollTop <= visualViewportHeight + buffer) {
          if (viewByCategory) {
            getTopCategoryPage();
          } else {
            getShopsPage();
          }
        }
      }, 500); // Set the debounce time interval (e.g., 250 milliseconds)
    };

    if (!loading && !disabled) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [loading, disabled]);

  const renderLoading = () => {
    return <Spinner />;
  };

  return (
    <>
      {searchResults.length !== 0 || searchProducts.length !== 0 ? (
        <div style={{padding: '0 100px'}}>
          <div
            key={'shops'}
            style={{
              width: '100%',
              background: 'white',
              padding: '20px',
              margin: '20px 0',
            }}>
            <h3 style={{color: '#000'}}> Shops </h3>

            <Carousel
              partialVisible={false}
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px">
              {searchResults.map((shop, index) => (
                <div
                  key={shop.id}
                  ref={el =>
                    index === shops.length - 1
                      ? (lastElementRef.current = el)
                      : null
                  }>
                  <StoreCard shop={shop} />
                </div>
              ))}
            </Carousel>
          </div>
          <div
            key={'shops'}
            style={{
              width: '100%',
              background: 'white',
              padding: '20px',
              margin: '20px 0',
            }}>
            <h3 style={{color: '#000'}}> Products </h3>

            <Carousel
              partialVisible={false}
              swipeable={true}
              draggable={false}
              showDots={false}
              responsive={responsive}
              infinite={false}
              autoPlay={false}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              customTransition="all .5"
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={['tablet', 'mobile']}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px">
              {searchProducts.map((product, index) => (
                <div
                  key={product.id}
                  ref={el =>
                    index === product.length - 1
                      ? (lastElementRef.current = el)
                      : null
                  }>
                  <ProductPreview
                    product={product}
                    shop={product.shop}
                    //addProductToCart={addProductToCart}
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      ) : (
        <div>
          <div id="topBanner">
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Combobox
                label="Select a country"
                placeholder="Select a country"
                defaultValue={country}
                onOptionSelect={(e, data) => {
                  setCountry(data.optionValue.country);
                  if (data.optionValue.states.length) {
                    setCities(data.optionValue.states);
                    setCity('');
                  }
                }}>
                {availableCountries
                  .filter(country => country.country)
                  .map(country => (
                    <Option value={country} key={country}>
                      {country.country}
                    </Option>
                  ))}
                {contriesComingSoon.map(country => (
                  <Option disabled value={country} key={country}>
                    {country}
                  </Option>
                ))}
              </Combobox>
              {cities.length ? (
                <Combobox
                  label="Select a state"
                  placeholder="Select a state"
                  defaultValue={city}
                  onOptionSelect={(e, data) => {
                    setCity(data.optionValue);
                  }}>
                  {cities
                    .filter(state => state.state)
                    .map(state => (
                      <Option value={state.state} key={state.state}>
                        {state.state}
                      </Option>
                    ))}
                </Combobox>
              ) : null}
              <Switch
                label="Group shops by category"
                onChange={() => {
                  setViewByCategory(!viewByCategory);
                }}
                checked={viewByCategory}
              />
            </div>

            {country === 'Nigeria' && (
              <div className="explore__banner">
                <NavLink
                  className={'explore__banner__link'}
                  to="/subscriptions">
                  <img
                    src={require('../../assets/images/healthcare.png')}
                    style={{height: '40px', marginRight: 10}}
                    alt="healthcare"
                  />
                  Healthcare plans available. Click here to learn more!
                </NavLink>
              </div>
            )}
          </div>
          <div style={{padding: '0 100px'}}>
            {loading ? (
              renderLoading()
            ) : viewByCategory ? (
              categories
                .filter(item => item.name !== 'Real Estate')
                .map((category, index) => (
                  <div
                    key={category.id}
                    style={{
                      width: '100%',
                      background: 'white',
                      padding: '20px',
                      margin: '20px 0',
                    }}>
                    <h3 style={{color: '#000'}}> {category.name} </h3>

                    <div>
                      <Carousel
                        partialVisible={false}
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        responsive={responsive}
                        infinite={false}
                        autoPlay={false}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={['tablet', 'mobile']}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px">
                        {category.shops.length > 0 &&
                          category.shops.map((shop, shopIndex) => (
                            <div
                              key={shop.id}
                              ref={el =>
                                index === categories.length - 1 &&
                                shopIndex === category.shops.length - 1
                                  ? (lastElementRef.current = el)
                                  : null
                              }>
                              <StoreCard shop={shop} />
                            </div>
                          ))}
                      </Carousel>
                    </div>
                  </div>
                ))
            ) : (
              <div
                className="explore"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  background: 'white',
                  padding: '20px',
                  margin: '20px 0',
                }}>
                {shops.map((shop, index) => (
                  <div
                    key={shop.id}
                    ref={el =>
                      index === shops.length - 1
                        ? (lastElementRef.current = el)
                        : null
                    }>
                    <StoreCard shop={shop} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
