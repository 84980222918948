import React from 'react';
import {Field, FormikProvider, useFormik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import {TextInput} from '../../../../../../components/Fields/TextInput/TextInput';
import {Button} from '../../../../../../components/Button/Button';
import {authEnum} from '../../Authentication';
import APIKit from '../../../../../../utils/Apikit/apikit';

let resetPassword = yup.object().shape({
  token: yup.string().required('Code is a required field.'),
  password: yup
    .string()
    .required()
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});

export const ResetPassword = ({goToLogin}) => {
  const onEmailSuccess = () => {
    toast.success('Password reset successfully.');
    goToLogin(authEnum.login);
  };

  const formik = useFormik({
    initialValues: {
      token: '',
      password: '',
    },
    validationSchema: resetPassword,
    onSubmit: values => {
      APIKit.post(`api/v1/users/auth/reset-password/confirm/`, values)
        .then(onEmailSuccess)
        .catch(error => {
          if (error.response) {
            toast.error(
              'Something went wrong. Please check your code and try again.',
            );
          }
        });
    },
  });

  return (
    <div className="formik">
      <span>Reset passwod</span>
      <FormikProvider value={formik}>
        <Field
          value={formik.values.token}
          name="token"
          placeholder="Code"
          title="Code*"
          component={TextInput}
          onChange={({target}) => formik.setFieldValue('token', target.value)}
        />
        <Field
          value={formik.values.password}
          name="password"
          placeholder="Password"
          title="New Password*"
          component={TextInput}
          type="password"
          onChange={({target}) =>
            formik.setFieldValue('password', target.value)
          }
        />
      </FormikProvider>

      <Button
        type="submit"
        onClick={() => formik.handleSubmit()}
        title="Reset password"
        style={{width: '100%'}}
      />

      <div className="user-exists">
        <span>Go back to </span>
        <span onClick={() => goToLogin(authEnum.login)}>login</span>
      </div>
    </div>
  );
};
