import React from 'react';
import {useState} from 'react';
import {useEffect} from 'react';
import APIKit from '../../utils/Apikit/apikit';
import {AddSubscription} from './components/AddSubscription';
import {Beneficiary} from './components/Beneficiary';
import './Subscriptions.css';

export const Subscriptions = () => {
  const [plans, setPlans] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [beneficiary, setBeneficiary] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPlan, setIsOpenPlan] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    getSubscriptions();
    getPlans();
    getBeneficiaries();
    getCards();
  }, []);

  const getSubscriptions = () => {
    const onSuccess = ({data}) => {
      console.log(data);
      setSubscriptions(data);
    };

    const onFailure = error => {
      console.log(error.response);
    };
    APIKit.get(`/api/v1/subscriptions/`).then(onSuccess).catch(onFailure);
  };

  const getCards = () => {
    const onSuccess = ({data}) => {
      setCards(data.cards);
    };

    const onFailure = error => {
      console.log(error.response);
    };
    APIKit.get(`/api/v1/users/list-cards/`).then(onSuccess).catch(onFailure);
  };

  const getBeneficiaries = () => {
    const onSuccess = ({data}) => {
      setBeneficiaries(data);
    };

    const onFailure = error => {
      console.log(error.response);
    };
    APIKit.get(`/api/v1/subscriptions/beneficiaries/`)
      .then(onSuccess)
      .catch(onFailure);
  };

  const getPlans = () => {
    const onSuccess = ({data}) => {
      setPlans(data);
    };

    const onFailure = error => {
      console.log(error.response);
    };
    APIKit.get(`/api/v1/subscriptions/plans/`).then(onSuccess).catch(onFailure);
  };

  const cancelSubscription = subscriptionId => {
    const onSuccess = () => {
      getSubscriptions();
    };

    const onFailure = error => {
      console.log(error.response);
    };

    APIKit.post(`/api/v1/subscriptions/${subscriptionId}/cancel/`)
      .then(onSuccess)
      .catch(onFailure);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openAddPlan = () => {
    setIsOpenPlan(true);
  };

  const closeAddPlan = () => {
    setIsOpenPlan(false);
  };

  const deleteBeneficiary = beneficiaryId => {
    const onSuccess = () => {
      getBeneficiaries();
    };

    const onFailure = error => {
      console.log(error.response);
    };

    APIKit.delete(`/api/v1/subscriptions/beneficiaries/${beneficiaryId}/`)
      .then(onSuccess)
      .catch(onFailure);
  };

  return (
    <div className="subscriptions">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <img
            src={require('../../assets/images/novo_health.png')}
            style={{
              height: '50px',
              width: 'fit-content',
            }}
            alt="healthcare"
          />
          <p
            style={{
              color: '#999',
              fontSize: '12px',
            }}>
            Healthcare plans available by Novo Health Africa. <br />
            <a href="https://crossrem1.com/content/LOAF.pdf" target={'_blank'}>
              Learn More About Novo Health Plans
            </a>
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <img
            src={require('../../assets/images/leadway.png')}
            style={{height: '50px', width: 'fit-content'}}
            alt="healthcare"
          />
          <p
            style={{
              color: '#999',
              fontSize: '12px',
            }}>
            Healthcare plans available by Leadway Health. <br />
            <a
              href="https://crossrem1.com/content/LEADWAY.pdf"
              target={'_blank'}>
              Learn More About Leadway Health Plans
            </a>
            {' - '}
            <a
              href="https://crossrem1.com/content/LEADWAY_SENIORS.pdf"
              target={'_blank'}>
              Seniors Plans
            </a>
          </p>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}>
        <h3>Beneficiaries</h3>
        <span
          onClick={() => {
            setBeneficiary({});
            setIsEdit(false);
            openModal();
          }}
          style={{
            color: '#30b868',
            width: '100%',
            textAlign: 'right',
            cursor: 'pointer',
            textDecoration: 'underline',
            marginTop: '2px',
            fontSize: '12px',
          }}>
          Add Beneficiary
        </span>
      </div>
      <div
        style={{
          width: '100%',
        }}>
        <div className="tableHeader">
          <span>Name</span>
          <span>Surame</span>
          <span>Phone Number</span>
          <span>E-Mail</span>
          <span>Birthday</span>
          <span>State</span>
          <span>Update</span>
          <span>Remove</span>
        </div>
      </div>
      {beneficiaries.length > 0 ? (
        <div
          style={{
            width: '100%',
          }}>
          {beneficiaries.map(beneficiary => (
            <div
              className="row"
              onClick={() => {
                setBeneficiary(beneficiary);
                setIsEdit(true);
                openAddPlan();
              }}>
              <span>{beneficiary.first_name} </span>
              <span>{beneficiary.last_name}</span>
              <span>{beneficiary.phone_number}</span>
              <span>{beneficiary.email}</span>
              <span>{beneficiary.birthday}</span>
              <span>{beneficiary.state}</span>
              <span
                onClick={event => {
                  event.stopPropagation();
                  setBeneficiary(beneficiary);
                  setIsEdit(true);
                  openModal();
                }}
                style={{
                  color: '#30b868',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginTop: '2px',
                  fontSize: '12px',
                }}>
                Edit
              </span>
              <span
                onClick={event => {
                  event.stopPropagation();
                  deleteBeneficiary(beneficiary.id);
                }}
                style={{
                  zIndex: 100,
                  color: '#30b868',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginTop: '2px',
                  fontSize: '12px',
                }}>
                Delete
              </span>
            </div>
          ))}
        </div>
      ) : (
        <p>
          You have no beneficiaries. Add a beneficiary to start a subscription.
        </p>
      )}

      {isOpen && (
        <Beneficiary
          isEdit={isEdit}
          beneficiary={beneficiary}
          modalIsOpen={isOpen}
          closeModal={closeModal}
          getBeneficiaries={getBeneficiaries}
        />
      )}

      <AddSubscription
        modalIsOpen={isOpenPlan}
        closeModal={closeAddPlan}
        beneficiary={beneficiary}
        plans={plans}
        cards={cards}
      />

      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}>
        <h3>Subscriptions</h3>
        <span className="disclaimer">
          <h3>Disclaimer</h3>
          <p>
            The subscriptions are healthcare plans served by Novo Health and
            Novo Health Policies apply
          </p>
        </span>
      </div>
      <div
        style={{
          width: '100%',
        }}>
        <div className="tableHeader">
          <span>Beneficiary Name</span>
          <span>Beneficiary Surame</span>
          <span>Plan Name</span>
          <span>PLan Price</span>
          <span>Plan Start Date</span>
          <span>Status</span>
          <span></span>
          <span>Cancel</span>
        </div>
      </div>
      {subscriptions.length > 0 ? (
        <div
          style={{
            width: '100%',
          }}>
          {subscriptions.map(sub => (
            <div className="row">
              <span>{sub?.beneficiary_info?.first_name}</span>
              <span>{sub?.beneficiary_info?.last_name}</span>
              <span>{sub?.plan_info?.title}</span>
              <span>${sub?.plan_info?.price}</span>
              <span>{sub?.square_info.start_date}</span>
              <span>{sub?.square_info.status}</span>
              <span></span>
              <span
                onClick={() => {
                  cancelSubscription(sub.id);
                }}
                style={{
                  zIndex: 100,
                  color: '#30b868',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginTop: '2px',
                  fontSize: '12px',
                }}>
                Cancel
              </span>
            </div>
          ))}
        </div>
      ) : (
        <p>You have no subscriptions. Add a subscription for a beneficiary.</p>
      )}

      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignContent: 'center',
          alignItems: 'center',
        }}>
        <div className="footnote">
          <p>
            *The healthcare provider will do the due dilligence and has the
            right to cancel the plan if there is inconsistencies.
          </p>
          <p>
            *The healthcare provider reserves the right to change the plan if
            there is a need to do so based on the age of the beneficiary.
          </p>
        </div>
      </div>
    </div>
  );
};
