import React from 'react';
import {useSetRecoilState} from 'recoil';
import {shoppingCartItems} from '../../../../store/shoppingCart';
import APIKit from '../../../../utils/Apikit/apikit';
import {getShopsCart} from '../../../../utils/apiService';

import './ShoppingCartItem.css';

export const ShoppingCartItem = ({item}) => {
  const setshoppingCartItems = useSetRecoilState(shoppingCartItems);

  const deleteCartItem = () => {
    APIKit.delete(`/api/v1/shops/cart-items/${item.id}/`).then(async () => {
      const cart = await getShopsCart(item.product_info.shop);
      setshoppingCartItems(cart);
    });
  };

  const onUpdateCartItem = quantity => {
    if (quantity) {
      const payload = {
        quantity: parseInt(quantity),
      };

      APIKit.patch(`/api/v1/shops/cart-items/${item.id}/`, payload).then(
        async () => {
          const cart = await getShopsCart(item.product_info.shop);
          setshoppingCartItems(cart);
        },
      );
    } else {
      deleteCartItem();
    }
  };

  return (
    <div className="shopping-cart-item">
      <img
        src={
          item.product_info.api_image_url
            ? item.product_info.api_image_url
            : item.product_info.image == null
            ? item.product_info.parent_image
            : item.product_info.image
        }
        alt="Crossrem"
      />
      <div
        style={{
          flex: 1,
          paddingLeft: '10px',
          flexDirection: 'column',
          textAlign: 'left',
        }}>
        <div className="shopping-cart-item-product">
          {item.product_info.name}
        </div>
        <div className="shopping-cart-item-amount">
          <div
            onClick={() => onUpdateCartItem(item.quantity + 1)}
            className="increase">
            +
          </div>
          <div className="amount">{item.quantity}</div>
          <div
            onClick={() => onUpdateCartItem(item.quantity - 1)}
            className="decrease">
            -
          </div>
        </div>
      </div>
      <div className="shopping-cart-item-price">
        <div className="price">${item.total_cost}</div>
        <div onClick={() => deleteCartItem()} className="remove">
          Remove
        </div>
      </div>
    </div>
  );
};
