import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import logo from '../../assets/svg/logo.svg';

import './VerifyEmail.css';
import {Spinner} from '../../components/Spinner/Spinner';
import APIKit from '../../utils/Apikit/apikit';

export const VerifyToken = () => {
  const [loading, setLoading] = useState(true);
  const {token} = useParams();

  useEffect(() => {
    APIKit.post(`/api/v1/users/verify-email/${token}/`)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div style={{display: 'flex', height: '100%'}}>
        <Spinner />
      </div>
    );
  }

  return (
    <div id="content">
      <img id="logo" src={logo} alt="Logo" />
      <h1>Email Verified!</h1>
      <h2>We're so happy to have you on our platform</h2>
    </div>
  );
};
