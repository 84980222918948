import React, {useEffect} from 'react';
import {Routes, Route, useNavigate, useLocation} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';
import {App} from '../pages/App/App';
import {Orders} from '../pages/Orders/Orders';
import {Explore} from '../pages/Explore/Explore';
import {Landing} from '../pages/Landing/Landing';
import {Shop} from '../pages/Shop/Shop';
import {tokenState} from '../store/token';
import {Signup} from '../pages/Landing/components/Authentication/components/Signup/Signup';
import {Account} from '../pages/Account/Account';
import {ResetPassword} from '../pages/Landing/components/Authentication/components/ResetPassword/ResetPassword';
import {Subscriptions} from '../pages/Subscriptions/Subscriptions';
import {VerifyToken} from '../pages/VerifyEmail/VerifyEmail';
import {RealEstate} from '../pages/RealEstate/RealEstate';

export const AppRoutes = () => {
  const setTokenState = useSetRecoilState(tokenState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setTokenState(token);
      if (!location.pathname.includes('verify')) navigate('/explore');
    }
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/verify/:token" element={<VerifyToken />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/business-registration" element={<Landing />} />
      <Route path="/" element={<App />}>
        <Route path="/explore" element={<Explore />} />
        <Route path="/orders" element={<Orders />} />
        <Route path="/realestate" element={<RealEstate />} />
        <Route path="/subscriptions" element={<Subscriptions />} />
        <Route path="/account" element={<Account />} />
        <Route
          path="/shop/:shopid"
          element={
            <React.Suspense fallback={<div>Loading...</div>}>
              <Shop />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};
