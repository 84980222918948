import React from 'react';
import {Authentication} from './components/Authentication/Authentication';
import './Landing.css';
import {Nav} from '../../components/Nav/Nav';

export const Landing = () => {
  return (
    <>
      <Nav />
      <div className="landing-page">
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: 'calc(100% - 110px)',
            background: '#30b868',
            opacity: 0.3,
            zIndex: 1,
          }}
        />

        <Authentication />
      </div>
    </>
  );
};
