import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {toast} from 'react-toastify';
import * as yup from 'yup';
import APIKit from '../../../utils/Apikit/apikit';
import {Button} from '../../../components/Button/Button';
import {TextInput} from '../../../components/Fields/TextInput/TextInput';
import {Modal} from '../../../components/Modal/Modal';

let signupSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  phone_number: yup.string().required(),
  birthday: yup.date().required(),
  email: yup.string(),
  state: yup.string(),
  bvn: yup.string(),
});

export const Beneficiary = ({
  isEdit,
  modalIsOpen,
  closeModal,
  getBeneficiaries,
  beneficiary,
}) => {
  const onSuccess = ({data}) => {
    toast.success(data);

    getBeneficiaries();
    if (isEdit) {
      toast.success('Beneficiary Edited! 👋');
    } else {
      toast.success('Beneficiary Added! 👋');
    }
    closeModal();
  };

  const initialValues = {
    first_name: beneficiary?.first_name || '',
    last_name: beneficiary?.last_name || '',
    phone_number: beneficiary?.phone_number || '',
    birthday: beneficiary?.birthday || '',
    email: beneficiary?.email || '',
    state: 'Nigeria',
    bvn: beneficiary?.bvn || '',
  };

  return (
    <Modal isOpen={modalIsOpen} onClose={closeModal}>
      <>
        <div className="shopping-cart-header">
          <span>{isEdit ? 'Edit Beneficiary' : 'Add Beneficiary'}</span>
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px 0',
            padding: '0 20px',
          }}
          className="shopping-cart-body">
          <Formik
            initialValues={initialValues}
            validationSchema={signupSchema}
            onSubmit={values => {
              console.log(values);
              if (isEdit) {
                APIKit.put(
                  `/api/v1/subscriptions/beneficiaries/${beneficiary.id}/`,
                  values,
                )
                  .then(onSuccess)
                  .catch(error => {
                    console.log(error);
                  });
              } else {
                APIKit.post(`/api/v1/subscriptions/beneficiaries/`, values)
                  .then(onSuccess)
                  .catch(error => {
                    console.log(error);
                  });
              }
            }}>
            {() => (
              <Form>
                <Field
                  name="first_name"
                  placeholder="Name"
                  title="Name*"
                  component={TextInput}
                />
                <Field
                  name="last_name"
                  placeholder="Surname"
                  title="Surname*"
                  component={TextInput}
                />
                <Field
                  name="phone_number"
                  placeholder="+234 ..."
                  title="Phone Number*"
                  component={TextInput}
                />
                <Field
                  name="email"
                  placeholder="Email"
                  title="Email*"
                  component={TextInput}
                />
                {/* Update to be a better datepicker */}

                <Field
                  name="birthday"
                  placeholder="2020-12-31"
                  title="Birthday*"
                  component={TextInput}
                />
                <Field
                  name="bvn"
                  placeholder="BVN"
                  title="BVN*"
                  component={TextInput}
                />
                <Button
                  type="submit"
                  title={isEdit ? 'Edit Recipient' : 'Add Recipient'}
                />
              </Form>
            )}
          </Formik>
        </div>
      </>
    </Modal>
  );
};
