import React, {useEffect, useState} from 'react';
import {Spinner} from '../../components/Spinner/Spinner';
import APIKit from '../../utils/Apikit/apikit';
import './RealEstate.css';
import GoogleMapReact from 'google-map-react';
import {Select, Label, Input, Button} from '@fluentui/react-components';
import {Property} from './Property';

export const RealEstate = props => {
  const [loading, setLoading] = useState(true);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const mapsKey = 'AIzaSyDqr2k15Og53GcuRQLyRCCStecqH2f0ogY';
  const defaultProps = {
    center: {
      //lagos
      lat: 6.5244,
      lng: 3.3792,
    },
    zoom: 11,
  };

  const [areaMin, setAreaMin] = useState(undefined);
  const [areaMax, setAreaMax] = useState(undefined);
  const [bedrooms, setBedrooms] = useState(1);
  const [baths, setBaths] = useState(1);
  const [yearBuildMin, setYearBuildMin] = useState(undefined);
  const [type, setType] = useState('FOR_SALE');
  const [minPrice, setMinPrice] = useState(undefined);
  const [maxPrice, setMaxPrice] = useState(undefined);

  const getProperties = () => {
    APIKit.get('/api/v1/realestate/properties/')
      .then(res => {
        setProperties(res.data);
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  const clearFilters = () => {
    setAreaMin(undefined);
    setAreaMax(undefined);
    setBedrooms(1);
    setBaths(1);
    setYearBuildMin(undefined);
    setType('FOR_SALE');
    setMinPrice(undefined);
    setMaxPrice(undefined);
    getProperties();
  };

  const searchProperties = () => {
    setProperties([]);
    setLoading(true);
    APIKit.get('/api/v1/realestate/properties/', {
      params: {
        area_min: areaMin,
        area_max: areaMax,
        beds_min: bedrooms,
        baths_min: baths,
        year_built_min: yearBuildMin,
        status: type,
        price_min: minPrice,
        price_max: maxPrice,
      },
    })
      .then(res => {
        setProperties(res.data);
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    getProperties();
    setLoading(true);
  }, []);

  const renderMarkers = (map, maps) => {
    properties.map(property => {
      console.log({lat: property.address.lat, lng: property.address.lng});
      return new maps.Marker({
        position: {
          lat: Number(property.address.lat),
          lng: Number(property.address.lng),
        },
        map,
        title: property.address.street,
      });
    });
  };

  const renderSpinner = () => {
    return <Spinner />;
  };

  const renderEmptyProperties = () => {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}>
        No Properties Found
        <Button
          id="search"
          appearance="primary"
          onClick={() => {
            clearFilters();
          }}>
          Clear Filters
        </Button>
      </div>
    );
  };

  document.onkeydown = function (evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key == 'Escape' || evt.key == 'Esc';
    } else {
      isEscape = evt.keyCode == 27;
    }
    if (isEscape) {
      setSelectedProperty(null)
    }
  };

  const renderProperties = () => {
    if (loading) return renderSpinner();

    if (properties.length === 0) {
      return renderEmptyProperties();
    }

    return properties.map((property, index) => (
      <div
        key={index}
        className="store-card"
        style={{cursor: 'pointer'}}
        onClick={() => setSelectedProperty(property)}>
        <img
          src={property.photos[0].photo}
          style={{height: '120px', width: '100%'}}
          alt="properties"
        />
        <div className="store-card-info">
          <div>
            <div className="store-card-info-description">
              {`${property.description.substring(0, 150)}${
                property.description.length > 150 ? '...' : null
              }`}
            </div>
          </div>
          <div>
            <div style={{fontSize: 12, fontWeight: 'bold'}}>
              Type: {property.status === 'FOR_SALE' ? 'For sale' : 'For rent'}
            </div>
            <div style={{fontSize: 12, fontWeight: 'bold'}}>
              Price: {property.price}
            </div>
            <div style={{fontSize: 12, fontWeight: 'bold'}}>
              Area: {property.area}m2
            </div>

            <div
              style={{
                fontSize: 12,
                marginTop: '5px',
              }}>{`${property.address.street}, ${property.address.city}, ${property.address.state}, ${property.address.country}`}</div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 10,
          marginBottom: 10,
        }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label htmlFor={'type'} size={props.size} disabled={props.disabled}>
            Property Type
          </Label>
          <Select
            id={'type'}
            value={type}
            onChange={(e, data) => {
              setType(data.value);
            }}
            {...props}>
            <option value={'FOR_SALE'}>For Sale</option>
            <option value={'FOR_RENT'}>For Rent</option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label
            htmlFor={'minprice'}
            size={props.size}
            disabled={props.disabled}>
            Minimun Price
          </Label>
          <Input
            id={'minprice'}
            value={minPrice}
            placeholder="No Minimun"
            onChange={(e, data) => {
              setMinPrice(data.value);
            }}
            type="number"
            {...props}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label
            htmlFor={'maxprice'}
            size={props.size}
            disabled={props.disabled}>
            Maximum Price
          </Label>
          <Input
            id={'maxprice'}
            value={maxPrice}
            placeholder="No Maximum"
            onChange={(e, data) => {
              setMaxPrice(data.value);
            }}
            type="number"
            {...props}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label
            htmlFor={'areanmin'}
            size={props.size}
            disabled={props.disabled}>
            Minimun Area (m2)
          </Label>
          <Input
            id={'areanmin'}
            value={areaMin}
            placeholder="No Minimun"
            onChange={(e, data) => {
              setAreaMin(data.value);
            }}
            type="number"
            {...props}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label
            htmlFor={'areanmax'}
            size={props.size}
            disabled={props.disabled}>
            Maximum Area (m2)
          </Label>
          <Input
            id={'areanmax'}
            value={areaMax}
            placeholder="No Maximum"
            onChange={(e, data) => {
              setAreaMax(data.value);
            }}
            type="number"
            {...props}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label
            htmlFor={'bedrooms'}
            size={props.size}
            disabled={props.disabled}>
            Bedrooms
          </Label>
          <Select
            id={'bedrooms'}
            value={bedrooms}
            onChange={(e, data) => {
              setBedrooms(data.value);
            }}
            {...props}>
            <option value={1}>1+ Bedrooms</option>
            <option value={2}>2+ Bedrooms</option>
            <option value={3}>3+ Bedrooms</option>
            <option value={4}>4+ Bedrooms</option>
            <option value={5}>5+ Bedrooms</option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label htmlFor={'baths'} size={props.size} disabled={props.disabled}>
            Baths
          </Label>
          <Select
            id={'baths'}
            value={baths}
            onChange={(e, data) => {
              setBaths(data.value);
            }}
            {...props}>
            <option value={1}>1+ Baths</option>
            <option value={2}>2+ Baths</option>
            <option value={3}>3+ Baths</option>
            <option value={4}>4+ Baths</option>
            <option value={5}>5+ Baths</option>
          </Select>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}>
          <Label
            htmlFor={'yearbuildmax'}
            size={props.size}
            disabled={props.disabled}>
            Build After
          </Label>
          <Input
            id={'yearbuildmax'}
            placeholder="No Minimun"
            onChange={(e, data) => {
              setYearBuildMin(data.value);
            }}
            value={yearBuildMin}
            type="number"
            {...props}
          />
        </div>
        <div>
          <div style={{height: 20}} /> {/* spacer */}
          <Button
            id="search"
            appearance="primary"
            onClick={() => {
              searchProperties();
            }}>
            Search
          </Button>
        </div>
      </div>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <div style={{height: 'calc(100vh - 130px)', width: '50%'}}>
          <GoogleMapReact
            key={JSON.stringify(properties)}
            bootstrapURLKeys={{key: mapsKey}}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            onGoogleApiLoaded={({map, maps}) => {
              renderMarkers(map, maps);
            }}
          />
        </div>
        <div
          style={{
            height: 'calc(100vh - 130px)',
            width: '50%',
            padding: '0 10px',
            display: 'flex',
            marginTop: '10px',
            flexDirection: 'row',
          }}>
          {renderProperties()}
        </div>
        {selectedProperty ? (
          <div
            className="popover"
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1000,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <div
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                fontSize: '30px',
                color: 'white',
                cursor: 'pointer',
              }}
              onClick={() => setSelectedProperty(null)}>
              X
            </div>
            <Property property={selectedProperty} />
          </div>
        ): null}
      </div>
    </div>
  );
};
