import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './Modal.css';

export const Modal = ({children, onClose, isOpen}) => {
  const handleClose = () => {
    onClose();
  };

  const handleBackdropClick = event => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };

  return (
    <div
      className={`modal ${isOpen ? 'show' : ''}`}
      onClick={handleBackdropClick}>
      <div className="modal-dialog" onClick={e => e.stopPropagation()}>
        <div className="modal-content">
          <div className="modal-header">
            <span onClick={() => handleClose()}>&times;</span>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

Modal.defaultProps = {
  onClose: () => {},
};
