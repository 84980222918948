import React from 'react';
import {Outlet} from 'react-router-dom';
import './App.css';
import {Nav} from '../../components/Nav/Nav';
import ChatwootWidget from '../../third-party/ChatWoot';

export const App = () => {
  return (
    <>
      <Nav />
      <div className="app">
        <Outlet />
        <ChatwootWidget />
      </div>
    </>
  );
};
