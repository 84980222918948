import React, {useState} from 'react';
import {Logo} from '../../../../components/Logo/logo';
import {Signup} from './components/Signup/Signup';
import {Login} from './components/Login/Login';

import './Authentication.css';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button} from '../../../../components/Button/Button';
import {ResetEmail} from './components/ResetPassword/ResetEmail';
import {BusinessRegistration} from './components/BusinessRegistration/BusinessRegistration';

export const authEnum = {
  login: 'LOGIN',
  signup: 'SIGNUP',
  reset: 'RESET',
  business: 'BUSINESS',
};

export const Authentication = () => {
  const location = useLocation();
  console.log(location.pathname);

  const [auth, setAuth] = useState(
    location.pathname == '/business-registration'
      ? authEnum.business
      : authEnum.login,
  );
  const navigate = useNavigate();

  const goTo = () =>
    location?.state?.shop
      ? navigate(`/shop/${location.state.shop.id}`, {
          state: location.state.shop,
        })
      : navigate('/explore');

  const handleSetAuth = type => setAuth(type);

  return (
    <div className="authentication">
      <Logo size={150} />

      <div className="exploreAuth">
        <Button
          title="Explore"
          style={{width: 300}}
          onClick={() => navigate('/explore')}
        />
        <h5>or</h5>
      </div>

      <div
        className="form"
        style={{
          height: '100%',
        }}>
        {auth === authEnum.login ? (
          <Login goToRegister={handleSetAuth} goTo={goTo} />
        ) : auth === authEnum.signup ? (
          <Signup goToLogin={handleSetAuth} goTo={goTo} />
        ) : auth == authEnum.reset ? (
          <ResetEmail goToLogin={handleSetAuth} goTo={goTo} />
        ) : (
          <BusinessRegistration goToLogin={handleSetAuth} goTo={goTo} />
        )}
      </div>
    </div>
  );
};
