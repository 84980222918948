import React, {useState} from 'react';

const ComingSoonImg = require('../../assets/images/comingsoon.png');

export const StoreImage = ({src}) => {
  if (src == null) {
    src = ComingSoonImg;
  }
  const [image, setImage] = useState(src);

  return (
    <img
      className="store-card-cover"
      src={image}
      alt="Crossrem"
      onError={e => {
        console.log(e);
        // e.target.value = ComingSoonImg;
        setImage(ComingSoonImg);
        console.log('error');
      }}
    />
  );
};
