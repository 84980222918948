import {useState} from 'react';

export const Property = ({property}) => {
  console.log(property);

  const photos = property.photos;
  const [photoIndex, setPhotoIndex] = useState(0);

  const showNextImage = () => {
    if (photoIndex === photos.length - 1) return;
    setPhotoIndex(photoIndex + 1);
  };

  const showPrevImage = () => {
    if (photoIndex === 0) return;
    setPhotoIndex(photoIndex - 1);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '800px',
        margin: '50px',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.75)',
      }}>
      <h2 style={{textAlign: 'left', width: '100%'}}>
        {property.address.street}, {property.address.city},{' '}
        {property.address.state}, {property.address.country}
      </h2>

      <img
        src={property.photos[photoIndex].photo}
        style={{
          width: '100%',
          height: '400px',
          objectFit: 'cover',
          borderRadius: '10px',
        }}
        alt="Crossrem"
      />
      <div
        style={{
          marginTop: '-90px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            height: '120px'
        }}>
        <div
          style={{
            display: 'flex',
            left: '20px',
            fontSize: '50px',
            color: 'white',
            cursor: 'pointer',
          }}>
          {photoIndex === 0 ? null : (
            <p
              onClick={() => showPrevImage()}
              style={{
                textShadow: '2px 2px 4px #000000',
              }}>
              {'<'}
            </p>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            right: '20px',
            fontSize: '50px',
            color: 'white',
            cursor: 'pointer',
          }}>
          {photoIndex === photos.length - 1 || photos.length <= 1 ? null : (
            <p
              onClick={() => showNextImage()}
              style={{
                textShadow: '2px 2px 4px #000000',
              }}>
              {'>'}
            </p>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '0',
          width: '100%',
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            marginBottom: '20px',
          }}>
          <h1
            style={{
              display: 'flex',
              fontSize: '30px',
              color: 'black',
              marginBottom: '0',
            }}>
            {property.price_currency}{' '}
            {Math.round(property.price)}
          </h1>
          <h3
            style={{
              fontSize: '15px',
              color: 'black',
              marginBottom: '0',
            }}>
            ${' '}{Math.round(property.price_usd)}
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '20px',
          }}>
          <h3
            style={{
              fontSize: '20px',
              color: 'black',
              marginBottom: '0',
            }}>
            {Math.round(property.beds)} Bedrooms
          </h3>
          <h3
            style={{
              fontSize: '20px',
              color: 'black',
              marginBottom: '0',
            }}>
            {Math.round(property.baths)} Bathrooms
          </h3>
        </div>
      </div>
      <p>{property.description}</p>
    </div>
  );
};
