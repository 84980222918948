import React from 'react';

import './Button.css';

export const Button = ({title, style, onClick, type, disabled}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabled}
      className="button">
      {title}
    </button>
  );
};
