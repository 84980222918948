import React, {useEffect, useState} from 'react';
import {Spinner} from '../../components/Spinner/Spinner';
import APIKit from '../../utils/Apikit/apikit';

import './Orders.css';

export const Orders = () => {
  const [paidOrders, setOrders] = useState({results: []});
  const [loading, setLoading] = useState(false);

  const getShops = page => {
    APIKit.get(`/api/v1/shops/carts/?is_purchased=True&page=${page}`)
      .then(res => {
        let data = res.data;

        setOrders({
          ...data,
          results: [...paidOrders?.results, ...data.results],
        });
        setLoading(false);
      })
      .catch(e => console.log(e));
  };

  useEffect(() => {
    setLoading(true);
    getShops(1);
  }, []);

  if (loading) {
    return <Spinner />;
  }

  const getPage = url => {
    const urlParams = new URLSearchParams(url);
    const page = urlParams.get('page');
    return page;
  };

  return (
    <div>
      <div className="orders">
        {paidOrders?.results &&
          paidOrders?.results.map(order => (
            <div className="shopping-cart-paid">
              <div className="shopping-cart-paid-header">
                <span>
                  {order.shop_info.name} - {order.shop_info.country} -
                  {order.shop_info.city}
                </span>
              </div>
              <div className="shopping-cart-paid-body">
                {order?.items.map(item => (
                  <div className="shopping-cart-item">
                    <img
                      src={
                        item.product_info.api_image_url
                          ? item.product_info.api_image_url
                          : item.product_info.image
                      }
                      alt="Crossrem"
                    />
                    <div className="shopping-cart-item-product">
                      {item.product_info.name} x {item.quantity}
                    </div>
                    <div className="shopping-cart-item-price">
                      <div className="price">${item.total_cost}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="shopping-cart-footer-paid">
                <div className="shopping-cart-footer-total-paid">
                  <span>Total </span>
                  <span>${order.total_amount}</span>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="pagination">
        {paidOrders.next && (
          <div onClick={() => getShops(getPage(paidOrders.next))}>
            Load more
          </div>
        )}
      </div>
    </div>
  );
};
