import React, {useEffect, useRef} from 'react';
import {useRecoilValue, useSetRecoilState} from 'recoil';

import {shoppingCartItems} from '../../store/shoppingCart';
import APIKit from '../../utils/Apikit/apikit';
import {Button} from '../Button/Button';
import {ShoppingCartItem} from './components/ShoppingCartItem/ShoppingCartItem';
import {toast} from 'react-toastify';
import './ShoppingCart.css';

import {tokenState} from '../../store/token';
import {useNavigate} from 'react-router-dom';
import {Checkout} from '../../pages/Checkout/Checkout';
import {AddRecipient} from '../../pages/Checkout/components/AddRecipient/AddRecipient';

export const ShoppingCart = ({shop}) => {
  const navigate = useNavigate();
  const shoppingCartItem = useRecoilValue(shoppingCartItems);
  const setShoppingCartItems = useSetRecoilState(shoppingCartItems);
  const [recipientAdd, setRecipientAdd] = React.useState(false);
  const [recipient, setRecipient] = React.useState('0');
  const [recipients, setRecipients] = React.useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const token = useRecoilValue(tokenState);
  const couponeref = useRef();

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const paymentCompleted = () => {
    setShoppingCartItems([]);
    setRecipient('0');
  };

  const goToLogin = () =>
    navigate('/', {
      state: {
        shop: shop,
      },
    });

  useEffect(() => {
    if (token) {
      getRecipients();
    }

    console.log('shoppingCartItem', shoppingCartItem);
    return () => setShoppingCartItems([]);
  }, []);

  const getRecipients = () => {
    APIKit.get(`/api/v1/deliveries/recipients/`)
      .then(res => {
        setRecipients(res.data);
      })
      .catch(e => console.log(e));
  };

  const addCouponeCode = cartId => {
    APIKit.post(`/api/v1/shops/carts/${cartId}/add_coupon_code/`, {
      coupon_code: couponeref.current.value,
    })
      .then(res => {
        setShoppingCartItems({results: [res.data]});
      })
      .catch(e => toast.error(e.response.data.error));
  };

  return (
    <div className="shopping-cart">
      <>
        <div className="shopping-cart-header">
          <span>Shopping Cart</span>
          {/* <span>Remove all</span> */}
        </div>
        <div className="shopping-cart-body">
          {!shoppingCartItem?.results?.[0]?.items.length && (
            <div
              style={{
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 'bold',
              }}>
              Select products from the shop's product list
            </div>
          )}
          {shoppingCartItem?.results?.[0]?.items.map(item => (
            <ShoppingCartItem item={item} />
          ))}
        </div>
        {token ? (
          <div className="shopping-cart-body">
            <div className="shopping-cart-header">
              <span>Recipient</span>
              {/* <span>Remove all</span> */}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                margin: '0 0px 20px 0',
                padding: '0 20px',
                flexDirection: 'column',
              }}
              className="shopping-cart-body">
              {recipients?.length ? (
                <>
                  <select
                    id="recipient"
                    style={{
                      height: '40px',
                      padding: '5px',
                      width: '300px',
                    }}
                    defaultValue={'0'}
                    defaultChecked={recipient}
                    onChange={e => setRecipient(e.target.value)}>
                    <option value="0">Select Recipient</option>
                    {recipients.map(recipient => {
                      return (
                        <option value={recipient.pk}>{recipient.label}</option>
                      );
                    })}
                  </select>
                </>
              ) : null}
              <span
                onClick={openModal}
                style={{
                  color: '#30b868',
                  width: '100%',
                  textAlign: 'right',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  marginTop: '2px',
                  fontSize: '12px',
                }}>
                Add Recipient
              </span>
            </div>

            <div className="coupone-code">
              <input
                placeholder="Coupon code"
                ref={couponeref}
                value={shoppingCartItem?.results?.[0]?.coupon_used}
              />
              <Button
                disabled={
                  shoppingCartItem?.results?.[0]?.coupon_used ||
                  !shoppingCartItem?.results?.[0]?.items.length
                }
                title="Apply code"
                onClick={() =>
                  addCouponeCode(shoppingCartItem?.results?.[0]?.id)
                }
              />
            </div>
          </div>
        ) : null}
        <div className="shopping-cart-footer">
          {token ? (
            <>
              <div className="shopping-cart-footer-total">
                <span>Subtotal </span>
                <span>${shoppingCartItem?.results?.[0]?.subtotal_amount}</span>
              </div>
              <div className="shopping-cart-footer-total">
                <span>Delivery fee </span>
                <span>
                  {shoppingCartItem?.results?.[0]?.shop_info?.delivery_fee}
                </span>
              </div>
              <div className="shopping-cart-footer-total">
                <span>Discount </span>
                <span>
                  ${shoppingCartItem?.results?.[0]?.discount_amount || 0}
                </span>
              </div>
              <div className="shopping-cart-footer-total">
                <span>Total </span>
                <span>
                  $
                  {parseFloat(
                    shoppingCartItem?.results?.[0]?.total_amount || 0,
                  ).toFixed(2)}
                </span>
              </div>

              <div>
                {recipient != 0 &&
                shoppingCartItem?.results?.[0]?.items?.length ? (
                  <Checkout
                    cartId={shoppingCartItem?.results?.[0]?.id}
                    recipient={recipient}
                    paymentCompleted={paymentCompleted}
                  />
                ) : (
                  <span>Please select or add a recipient!</span>
                )}
              </div>
            </>
          ) : (
            <Button title="Login" onClick={() => goToLogin()} />
          )}
        </div>

        <AddRecipient
          setRecipientAdd={setRecipientAdd}
          getRecipients={getRecipients}
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
        />
      </>
    </div>
  );
};
