import axios from 'axios';

// Create axios client, pre-configured with baseURL
let APIKit = axios.create({
  //baseURL: 'http://10.0.2.2:8000',
  baseURL:
    !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'https://crossrem-4-bpb8u.ondigitalocean.app' //'https://crossrem-staging-89xcp.ondigitalocean.app/'
      : 'https://crossrem-4-bpb8u.ondigitalocean.app',
  timeout: 10000,
});

// Set JSON Web Token in Client to be included in all calls
export const setClientToken = token => {
  localStorage.setItem('token', token);
  if (token) {
    APIKit.interceptors.request.use(function (config) {
      config.headers.Authorization = `Token ${
        token || localStorage.setItem('token', token)
      }`;
      return config;
    });
  }
};

export default APIKit;
