import {atom} from 'recoil';

export const shoppingCartItems = atom({
  key: 'shoppingCartItems',
  default: [],
});

export const shoppingList = atom({
  key: 'ShoppingList',
  default: [],
});

export const shoppingCart = atom({
  key: 'ShoppingCart',
  default: false,
});
