import {useState, useEffect} from 'react';
import {Button} from '../Button/Button';
import {useRef} from 'react';

export const Product = ({product, addProductToCart}) => {
  const [variant, setVariant] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [images, setImages] = useState([
    product.api_image_url || product.image,
  ]);
  const [image, setImage] = useState(images[0]);
  const [imageIndex, setImageIndex] = useState(0);

  // Prevent useEffect from running on initial render
  const dataFetch = useRef(false);

  useEffect(() => {
    if (dataFetch.current) return;
    dataFetch.current = true;
    if (product) {
      const extra_images = product.extra_images.map(image => image.image);
      setImages([...images, ...extra_images]);
    }
  }, []);

  if (!product) {
    return null;
  }

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //when you pres esc key trigger handleCloseModal
  document.onkeydown = function (evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key == 'Escape' || evt.key == 'Esc';
    } else {
      isEscape = evt.keyCode == 27;
    }
    if (isEscape) {
      handleCloseModal();
    }
  };

  const showNextImage = () => {
    if (imageIndex === images.length - 1) return;
    setImageIndex(imageIndex + 1);
    setImage(images[imageIndex + 1]);
  };

  const showPrevImage = () => {
    if (imageIndex === 0) return;
    setImageIndex(imageIndex - 1);
    setImage(images[imageIndex - 1]);
  };

  return (
    <div className="shop-list-product">
      <div style={{display: 'flex', width: '80%'}}>
        <img
          src={product.api_image_url ? product.api_image_url : product.image}
          alt="Crossrem"
          onClick={handleImageClick}
        />
        <div className="description">
          <div style={{fontWeight: 'bold'}}>
            {variant == 0 ? product.name : product.variants[variant - 1].name}
          </div>
          <div style={{fontSize: '12px'}}>
            {variant == 0
              ? product.description
              : product.variants[variant - 1].description}
          </div>
          <div
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              color: '#30b868',
            }}>
            {product.selling_currency}{' '}
            {variant == 0
              ? product.selling_price
              : product.variants[variant - 1].selling_price}
          </div>
          {product.variants && product.variants.length > 0 && (
            <select
              id="recipient"
              style={{
                height: '40px',
                padding: '5px',
                width: '300px',
              }}
              onChange={e => setVariant(e.target.value)}>
              <option value={0}>{product.name}</option>
              {product.variants.map((variant, index) => {
                return (
                  <option key={variant.id} value={index + 1}>
                    {variant.name}
                  </option>
                );
              })}
            </select>
          )}
        </div>
      </div>
      <Button
        onClick={() =>
          addProductToCart(
            variant == 0 ? product : product.variants[variant - 1],
          )
        }
        title="Add to cart"
      />
      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleCloseModal}>
          <div
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '30px',
              color: 'white',
              cursor: 'pointer',
            }}>
            X
          </div>
          <div
            style={{
              position: 'relative',
              width: '80%',
              height: '80%',
              maxWidth: '800px',
              maxHeight: '600px',
            }}
            onClick={e => e.stopPropagation()}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '20px',
                fontSize: '50px',
                color: 'white',
                cursor: 'pointer',
              }}>
              {imageIndex === 0 ? null : (
                <p
                  onClick={() => showPrevImage()}
                  style={{
                    textShadow: '2px 2px 4px #000000',
                  }}>
                  {'<'}
                </p>
              )}
            </div>
            <img
              src={image}
              alt="Crossrem"
              style={{width: '100%', height: '100%', objectFit: 'contain'}}
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                right: '20px',
                fontSize: '50px',
                color: 'white',
                cursor: 'pointer',
              }}>
              {imageIndex === images.length - 1 || images.length <= 1 ? null : (
                <p
                  onClick={() => showNextImage()}
                  style={{
                    textShadow: '2px 2px 4px #000000',
                  }}>
                  {'>'}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
