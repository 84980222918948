import React from 'react';
import {Field, Form, Formik} from 'formik';
import {toast} from 'react-toastify';
import * as yup from 'yup';
import APIKit from '../../../../utils/Apikit/apikit';
import {Button} from '../../../../components/Button/Button';
import {TextInput} from '../../../../components/Fields/TextInput/TextInput';
import {Modal} from '../../../../components/Modal/Modal';

const header = {
  'content-type': 'application/json',
};

let signupSchema = yup.object().shape({
  name: yup.string().required(),
  surname: yup.string().required(),
  phone_number: yup.string().required(),
  email: yup.string(),
  street_address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
});

export const AddRecipient = ({modalIsOpen, closeModal, getRecipients}) => {
  const onSuccess = ({data}) => {
    toast.success(data);

    getRecipients();
    toast.success('Recipient Created! 👋');
    closeModal();
  };

  return (
    <Modal isOpen={modalIsOpen} onClose={closeModal}>
      <>
        <div className="shopping-cart-header">
          <span>Add Recipient</span>
        </div>
        <div
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '20px 0',
            padding: '0 20px',
          }}
          className="shopping-cart-body">
          <Formik
            initialValues={{
              name: '',
              surname: '',
              phone_number: '',
              street_address: '',
              email: '',
              city: '',
              state: '',
              country: 'Nigeria',
            }}
            validationSchema={signupSchema}
            onSubmit={values => {
              const payload = {
                ...values,
                label: values.name + ' ' + values.surname,
              };
              APIKit.post(`/api/v1/deliveries/recipients/`, payload, {
                headers: header,
              })
                .then(onSuccess)
                .catch(error => {
                  //Temporary - Need to Delete

                  if (error.response) {
                    console.log('E1', error.response.data);
                    console.log('E2', error.response.status);
                    console.log('E3', error.response.headers);
                  } else if (error.request) {
                    console.log('RQ', error.request);
                  } else {
                    console.log('Error', error.message);
                  }
                  console.log('CF', error.config);
                });
            }}>
            {() => (
              <Form>
                <Field
                  name="name"
                  placeholder="Name"
                  title="Name*"
                  component={TextInput}
                />
                <Field
                  name="surname"
                  placeholder="Surname"
                  title="Surname*"
                  component={TextInput}
                />
                <Field
                  name="phone_number"
                  placeholder="+234 ..."
                  title="Phone Number*"
                  component={TextInput}
                />
                <Field
                  name="email"
                  placeholder="Email"
                  title="Email*"
                  component={TextInput}
                />
                <Field
                  name="street_address"
                  placeholder="Address"
                  title="Address*"
                  component={TextInput}
                />
                <Field
                  name="city"
                  placeholder="City"
                  title="City*"
                  component={TextInput}
                />
                <Field
                  name="state"
                  placeholder="State"
                  title="State*"
                  component={TextInput}
                />
                <Button type="submit" title="Add Recipient" />
              </Form>
            )}
          </Formik>
        </div>
      </>
    </Modal>
  );
};
