import React from 'react';
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import {useSearchParams} from 'react-router-dom';

import {TextInput} from '../../../../../../components/Fields/TextInput/TextInput';
import {Button} from '../../../../../../components/Button/Button';
import {authEnum} from '../../Authentication';
import APIKit, {setClientToken} from '../../../../../../utils/Apikit/apikit';
import {useSetRecoilState} from 'recoil';
import {tokenState} from '../../../../../../store/token';

let signupSchema = yup.object().shape({
  email: yup.string().email().required(),
  password1: yup.string().required('Password is a required field.'),
  password2: yup
    .string()
    .required('Repeat password a is required field.')
    .oneOf([yup.ref('password1'), null], 'Passwords must match'),
});

export const Signup = ({goToLogin, goTo}) => {
  const setTokenState = useSetRecoilState(tokenState);
  const header = {
    'content-type': 'application/json',
  };

  const [query, setQuery] = useSearchParams();
  const rebate = query.get('code');

  const onSuccess = ({data}) => {
    toast.success('Account Created! Welcome to CrossRem 👋');
    setClientToken(data.token);
    setTokenState(data.token);
    goTo();
  };

  return (
    <div className="formik" style={{padding: 20}}>
      <span>Create your account</span>
      <Formik
        initialValues={{
          email: '',
          password1: '',
          password2: '',
          referral_code_used: rebate,
        }}
        validationSchema={signupSchema}
        onSubmit={(values, actions) => {
          APIKit.post(
            `/api/v1/users/registration/`,
            {...values, referral_code_used: values.referral_code_used || ''},
            {
              headers: header,
            },
          )
            .then(onSuccess)
            .catch(error => {
              if (error.response) {
                toast.error(error.response.data.error);
              } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js

                console.log('RQ', error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
              }
              console.log('CF', error.config);
            });
        }}>
        {() => (
          <Form>
            {/* <Field
              name="username"
              placeholder="Username"
              title="Username*"
              component={TextInput}
            /> */}
            {/* <Field
              name="billingaddress"
              placeholder="Street"
              title="Billing address"
              component={TextInput}
            />
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <Field
                half
                name="state"
                placeholder="State / Province"
                component={TextInput}
              />
              <Field
                half
                name="country"
                placeholder="Country"
                component={TextInput}
              />
            </div>
            <Field
              name="phone"
              placeholder="Phone number"
              title="Phone number*"
              component={TextInput}
            /> */}
            <Field
              name="email"
              placeholder="Email"
              title="Email*"
              component={TextInput}
            />
            <Field
              name="password1"
              placeholder="Password"
              title="Password*"
              component={TextInput}
              type="password"
            />
            <Field
              name="password2"
              placeholder="Repeat Password"
              title="Repeat Password*"
              component={TextInput}
              type="password"
            />
            <Field
              name="referral_code_used"
              placeholder="Rebate Code"
              title="Rebate"
              component={TextInput}
              type="input"
            />
            <Button
              type="submit"
              title="Register Now"
              style={{width: '100%'}}
            />

            <div className="user-exists">
              <span>I’m already member?</span>
              <span onClick={() => goToLogin(authEnum.login)}> Login</span>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
