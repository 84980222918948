import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import {TextInput} from '../../../../../../components/Fields/TextInput/TextInput';
import {Button} from '../../../../../../components/Button/Button';
import {authEnum} from '../../Authentication';
import APIKit from '../../../../../../utils/Apikit/apikit';
import {ResetPassword} from './ResetPassword';

let email = yup.object().shape({
  email: yup.string().email().required(),
});

export const ResetEmail = ({goToLogin}) => {
  const [reset, setReset] = useState(false);

  const onEmailSuccess = () => {
    setReset(true);
    toast.success('If this email exists you will recieve an email shortly. ');
  };

  if (!reset) {
    return (
      <div className="formik">
        <span>Reset passwod</span>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={email}
          onSubmit={(values, actions) => {
            APIKit.post(`api/v1/users/auth/reset-password/`, values)
              .then(onEmailSuccess)
              .catch(error => {
                if (error.response) onEmailSuccess();
              });
          }}>
          {() => (
            <Form>
              <Field
                name="email"
                placeholder="Email"
                title="Email*"
                component={TextInput}
              />
              <Button
                type="submit"
                title="Send email"
                style={{width: '100%'}}
              />

              <div className="user-exists">
                <span>Go back to </span>
                <span onClick={() => goToLogin(authEnum.login)}>login</span>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }

  return <ResetPassword goToLogin={goToLogin} />;
};
