import React, {useEffect, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import * as yup from 'yup';
import {toast} from 'react-toastify';
import {Button} from '../../components/Button/Button';
import {TextInput} from '../../components/Fields/TextInput/TextInput';
import APIKit from '../../utils/Apikit/apikit';

import './Profile.css';
import {Spinner} from '../../components/Spinner/Spinner';
import {countryCodes} from '../../utils/countrycodes';
let profileSchema = yup.object().shape({
  email: yup.string().email().required(),
});

export const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [city, setCity] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [billing_address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    onGetProfile();
  }, []);

  const onGetProfile = () => {
    const onSuccess = ({data}) => {
      const {
        billing_address,
        city,
        country,
        email,
        first_name,
        last_name,
        phone_number,
        image,
      } = data;
      setFirst_name(first_name);
      setLast_name(last_name);
      setCity(city);
      setPhoneNumber(phone_number);
      setCountry(country);
      setAddress(billing_address);
      setEmail(email);
      setIsLoading(false);
      setImageData(image);
    };

    // Show spinner when call is made
    setIsLoading(true);

    APIKit.get(`/api/v1/users/profile/`).then(onSuccess);
  };

  const updateImage = val => {
    const onSuccess = ({data}) => {
      setImageData(data.image);
      setIsLoading(false);
      toast.success('Profile photo is updated.');
    };

    const onFailure = error => {
      error = error && error.response.data;
      error = error[Object.keys(error)[0]];
      toast.error('There was an issue.');
      setIsLoading(false);
    };

    // Show spinner when call is made
    setIsLoading(true);

    let formData = new FormData();
    formData.append('image', val);

    APIKit.put(`api/v1/users/profile/`, formData)
      .then(onSuccess)
      .catch(onFailure);
  };

  const onPressSave = values => {
    console.log(values);
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      city: values.city,
      country: country,
      //Enable this later react-native-phone-number-input
      phone_number: values.phone_number,
      billing_address: values.billing_address,
    };

    console.log(payload);

    const onSuccess = ({data}) => {
      const {
        billing_address,
        city,
        country: country,
        email,
        first_name,
        last_name,
        phone_number,
      } = data;
      setFirst_name(first_name);
      setLast_name(last_name);
      setCity(city);
      setPhoneNumber(phone_number);
      setCountry(country);
      setAddress(billing_address);
      setEmail(email);
      setIsLoading(false);
      setIsEdit(false);
      toast.success('Profile Updated.');
    };

    const onFailure = error => {
      console.log('response');
      error = error && error.response.data;
      error = error[Object.keys(error)[0]];
      console.log(error);
      toast.error(`There was an issue. ${error}`);
      setIsLoading(false);
    };

    // Show spinner when call is made
    setIsLoading(true);

    APIKit.put(`api/v1/users/profile/`, payload)
      .then(onSuccess)
      .catch(onFailure);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="profile">
      <div className="general">
        <div className="image-upload">
          <input
            type="file"
            id="logo"
            onChange={evt => updateImage(evt.target.files[0])}
          />
          <label for="logo" className="upload-field" id="file-label">
            <div className="file-thumbnail">
              <img
                id="image-preview"
                src={
                  imageData
                    ? imageData
                    : 'https://www.btklsby.go.id/images/placeholder/basic.png'
                }
                alt="Crossrem"
              />
            </div>
          </label>
        </div>
        <div className="fullname">{first_name + ' ' + last_name}</div>
      </div>
      <div>
        <Formik
          initialValues={{
            first_name,
            last_name,
            city,
            phone_number,
            country,
            billing_address,
            email,
          }}
          validationSchema={profileSchema}
          onSubmit={(values, setFieldValue) => {
            onPressSave(values);
          }}>
          {() => (
            <Form className="forminputs">
              <Field
                name="email"
                placeholder="Email"
                title="Email*"
                component={TextInput}
                disabled={!isEdit}
              />
              <Field
                name="phone_number"
                placeholder="Phone"
                title="Phone*"
                component={TextInput}
                disabled={!isEdit}
              />
              <Field
                name="first_name"
                placeholder="First Name"
                title="First Name*"
                component={TextInput}
                disabled={!isEdit}
              />
              <Field
                name="last_name"
                placeholder="Last Name"
                title="Last Name*"
                component={TextInput}
                disabled={!isEdit}
              />
              <Field
                name="city"
                placeholder="City"
                title="City*"
                component={TextInput}
                disabled={!isEdit}
              />
              <Field
                name="billing_address"
                placeholder="Street & House number"
                title="Street & House number*"
                component={TextInput}
                disabled={!isEdit}
              />

              <select
                id="country"
                name="country"
                defaultValue={country}
                disabled={!isEdit ? 'disabled' : ''}
                style={{
                  height: '40px',
                  padding: '5px',
                  width: '100%',
                  borderColor: '#e0e0e0',
                  flex: '45% 1',
                  marginRight: '5%',
                }}
                onChange={e => {
                  setCountry(e.target.value);
                  console.log(e.target.value);
                }}>
                {Object.keys(countryCodes).map((key, index) => {
                  let val = countryCodes[key];
                  return <option value={key}>{key + ' - ' + val}</option>;
                })}
              </select>

              <div>
                {!isEdit ? (
                  <Button
                    onClick={e => {
                      e.preventDefault();
                      setIsEdit(true);
                    }}
                    title="Edit profile"
                  />
                ) : (
                  <Button type="submit" title="Update" />
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
