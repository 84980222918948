import {useState} from 'react';
import {Button} from '../Button/Button';
import {useNavigate} from 'react-router-dom';

export const ProductPreview = ({product, shop}) => {
  const [variant, setVariant] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  if (!product) {
    return null;
  }

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  //when you pres esc key trigger handleCloseModal
  document.onkeydown = function (evt) {
    evt = evt || window.event;
    var isEscape = false;
    if ('key' in evt) {
      isEscape = evt.key == 'Escape' || evt.key == 'Esc';
    } else {
      isEscape = evt.keyCode == 27;
    }
    if (isEscape) {
      handleCloseModal();
    }
  };

  return (
    <div
      className="shop-list-product"
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '200px',
        maxWidth: '200px',
        marginRight: '10px',
        backgroundColor: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
        height: '350px',
        marginBottom: '10px',
        justifyContent: 'space-between',
        position: 'relative',
      }}>
      <div>
      <div
          style={{
            fontSize: '14px',
            fontWeight: 'bold',
            backgroundColor: '#30b868',
            padding: 5,
            color: 'white',
            position: 'absolute',
            top: 10,
          }}>
          {product.selling_currency}{' $ '}
          {variant == 0
            ? product.selling_price
            : product.variants[variant - 1].selling_price}
        </div>
        <img
          src={product.api_image_url ? product.api_image_url : product.image}
          alt="Crossrem"
          style={{width: '210px', height: '140px', objectFit: 'cover'}}
          onClick={handleImageClick}
        />

        <div className="description" style={{padding: '10px'}}>
          {' '}
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#30b868',
            }}>
            {product.shop.name}
          </div>
          <div style={{fontWeight: 'bold', fontSize: '15px'}}>
            {variant == 0 ? product.name : product.variants[variant - 1].name}
          </div>
          <div style={{fontSize: '12px'}}>
            {variant == 0
              ? product.description
              : product.variants[variant - 1].description}
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          backgroundColor: 'white',
          padding: '10px',
          position: 'absolute',
          bottom: 0,
          width: '180px',
        }}>
        <Button
          onClick={() => shop.is_active && navigate('/shop', {state: shop})}
          title="Go to Store"
        />
      </div>
      {showModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={handleCloseModal}>
          <div
            onClick={handleCloseModal}
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              fontSize: '30px',
              color: 'white',
              cursor: 'pointer',
            }}>
            X
          </div>
          <div
            style={{
              position: 'relative',
              width: '80%',
              height: '80%',
              maxWidth: '800px',
              maxHeight: '600px',
            }}
            onClick={e => e.stopPropagation()}>
            <img
              src={
                variant == 0
                  ? product.api_image_url || product.image
                  : product.variants[variant - 1].api_image_url ||
                    product.variants[variant - 1].image
              }
              alt="Crossrem"
              style={{width: '100%', height: '100%', objectFit: 'contain'}}
            />
          </div>
        </div>
      )}
    </div>
  );
};
