import React, {useEffect, useState} from 'react';
import {PaymentForm, CreditCard} from 'react-square-web-payments-sdk';
import {APPLICATION_PAYMENT_ID, LOCATION_ID} from '../../../../utils/constants';
import APIKit from '../../../../utils/Apikit/apikit';
import {useNavigate} from 'react-router-dom';
import {Button} from '../../../../components/Button/Button';
import {Input} from '../../../../components/Fields/TextInput/TextInput';
import {countryCodes} from '../../../../utils/countrycodes';
import {useFormik} from 'formik';
import {Modal} from '../../../../components/Modal/Modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '200px',
    display: 'flex',
    alignItems: 'center',
  },
};

export const AddCard = ({modalIsOpen, closeModal, addCard}) => {
  const navigate = useNavigate();
  const [isCompleted, setIsCompeleted] = useState(false);
  const [userData, setUserData] = useState({
    addressLines: '',
    city: '',
    region: '',
    country: '',
    email: '',
    givenName: '',
    familyName: '',
    phone: '',
  });

  const formik = useFormik({
    initialValues: userData,
  });

  useEffect(() => {
    if (checkProperties(formik.values)) {
      setUserData(formik.values);
      setIsCompeleted(true);
    } else {
      setIsCompeleted(false);
    }
  }, [formik.values]);

  useEffect(() => {
    if (modalIsOpen) {
      onGetProfile();
    }
  }, [modalIsOpen]);

  const onGetProfile = () => {
    const onSuccess = ({data}) => {
      const userDatas = {
        addressLines: data.billing_address,
        city: data.city,
        region: data.country,
        country: data.country,
        email: data.email,
        givenName: data.first_name,
        familyName: data.last_name,
        phone: data.phone_number,
      };
      formik.setValues(userDatas);
      setUserData(userDatas);
    };

    APIKit.get(`/api/v1/users/profile/`).then(onSuccess);
  };

  const checkProperties = datas => {
    for (var key in datas) {
      if (datas[key] === '') return false;
    }
    return true;
  };

  return (
    <Modal isOpen={modalIsOpen} onClose={closeModal}>
      {userData && !checkProperties() ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
          <div>Please fill all the profile data before adding the card</div>
          <Button
            style={{marginTop: '20px'}}
            title="Go to profile"
            onClick={() => navigate('/account')}
          />
        </div>
      ) : (
        <div>
          <div style={{marginBottom: '30px'}}>
            <Input
              name="email"
              placeholder="Email"
              title="Email*"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <Input
              name="phone"
              placeholder="Phone"
              title="Phone*"
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
            <Input
              name="givenName"
              placeholder="First Name"
              title="First Name*"
              onChange={formik.handleChange}
              value={formik.values.givenName}
            />
            <Input
              name="familyName"
              placeholder="Last Name"
              title="Last Name*"
              onChange={formik.handleChange}
              value={formik.values.familyName}
            />
            <Input
              name="city"
              placeholder="City"
              title="City*"
              onChange={formik.handleChange}
              value={formik.values.city}
            />
            <Input
              disabled
              name="region"
              placeholder="Region"
              title="Region*"
              value={formik.values.region}
            />
            <Input
              name="addressLines"
              placeholder="Street & House number"
              title="Street & House number*"
              onChange={formik.handleChange}
              value={formik.values.addressLines}
            />
            <span className="input-title">Country code</span>
            <select
              id="country"
              name="country"
              defaultValue={formik.values.country}
              style={{
                height: '40px',
                padding: '5px',
                width: '100%',
                borderColor: '#e0e0e0',
                flex: '45% 1',
                marginRight: '5%',
              }}
              value={formik.values.country}
              onChange={e => {
                console.log(countryCodes[e.target.value], e.target.value);
                formik.setFieldValue('country', countryCodes[e.target.value]);
                formik.setFieldValue('region', e.target.value);
              }}>
              {Object.keys(countryCodes).map((key, index) => {
                let val = countryCodes[key];
                return <option value={key}>{key + ' - ' + val}</option>;
              })}
            </select>
          </div>

          <PaymentForm
            applicationId={APPLICATION_PAYMENT_ID}
            locationId={LOCATION_ID}
            createVerificationDetails={() => ({
              // `CHARGE` or `STORE`
              intent: 'STORE',
              billingContact: {
                ...userData,
                addressLines: [userData.addressLines],
              },
            })}
            cardTokenizeResponseReceived={(token, verifiedBuyer) => {
              console.info('Token:', token);
              console.info('Verified Buyer:', verifiedBuyer);
              addCard(token.token);
            }}>
            <CreditCard
              buttonProps={{
                isLoading: !isCompleted,
                css: {
                  backgroundColor: '#30b868',
                },
              }}
              callbacks={{
                onSubmit(data) {
                  console.log('TOKEN', JSON.stringify(data));
                },
              }}>
              Save card
            </CreditCard>
          </PaymentForm>
        </div>
      )}
    </Modal>
  );
};
