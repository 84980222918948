import React, {useState, useEffect, useCallback} from 'react';
import {ShoppingCart, Menu as MenuIcon} from 'feather-icons-react';
import {Logo} from '../Logo/logo';

import './Nav.css';
import {Menu} from '../../pages/App/components/Menu/Menu';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import {tokenState} from '../../store/token';
import {shoppingCart, shoppingCartItems} from '../../store/shoppingCart';
import {useLocation, useNavigate} from 'react-router-dom';
import {Search} from 'feather-icons-react/build/IconComponents';
import {masterSearch} from '../../store/systemStore';

const privateRout = ['/profile'];

export const Nav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setTokenState = useSetRecoilState(tokenState);
  const setShoppingCart = useSetRecoilState(shoppingCart);
  const shoppingCartState = useRecoilValue(shoppingCart);
  const setSearchState = useSetRecoilState(masterSearch);
  const masterSearchInput = useRecoilValue(masterSearch);
  const token = useRecoilValue(tokenState);
  const setshoppingCartItems = useSetRecoilState(shoppingCartItems);
  const [showMenu, setShowMenu] = useState(
    window.innerWidth > 700 ? true : false,
  );

  const screenChange = useCallback(() => {
    console.log(window.innerWidth);
    if (window.innerWidth > 700) {
      setShowMenu(true);
    } else {
      setShowMenu(false);
    }
  }, [setShowMenu]);

  useEffect(() => {
    window.addEventListener('resize', screenChange);
    return () => window.removeEventListener('resize', screenChange);
  }, [screenChange]);

  const logout = () => {
    localStorage.removeItem('token');
    setTokenState(null);
    setshoppingCartItems([]);
    if (privateRout.includes(location.pathname)) {
      navigate('/explore');
    }

    if (showMenu && window.innerWidth < 700) {
      setShowMenu(false);
    }
  };

  return (
    <div className="nav">
      <div className="topNav">
        <Logo menu size={150} />
        <div id="masterSearch">
          <Search className="searchIcon" size={30} color="#000" />
          <input
            type="text"
            placeholder="Search"
            value={masterSearchInput}
            className="searchBar"
            onChange={input => setSearchState(input.target.value)}
          />
          <input
            type="button"
            value="Search"
            className="searchButton"
            onClick={() => navigate('/explore')}
          />
        </div>

        <div className="nav-right">
          <div className="nav-user">
            {token ? (
              <span style={{cursor: 'pointer'}} onClick={logout}>
                Logout
              </span>
            ) : (
              <span style={{cursor: 'pointer'}} onClick={() => navigate('/')}>
                Login
              </span>
            )}
          </div>

          {location.pathname === '/shop' && (
            <ShoppingCart
              className="shop-icon"
              size={30}
              color="#ffffff"
              onClick={() => setShoppingCart(!shoppingCartState)}
            />
          )}

          <MenuIcon
            className="menu-icon"
            size={30}
            color="#ffffff"
            onClick={() => setShowMenu(!showMenu)}
          />
        </div>
      </div>
      <div className="bottomNav">
        {showMenu ? (
          <Menu token={token} logout={logout} showMenu={showMenu} />
        ) : null}
      </div>
    </div>
  );
};
